/*
FONTS START
*/
:root {
    height: 100%;
    --toastify-toast-offset: 24px !important;
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: "Inter";
    src: url("../fonts/inter/Inter-ExtraBold.ttf");
    font-weight: 800;
  }
  /*
  FONTS END
  */
  html,
  body {
    overflow: hidden;
  }
  
  body {
    touch-action: none !important;
  }
  
  html {
    --dark: #120a29;
    --purple: #2e0453;
    --white: #fff;
    --bronze: #60390b;
    --silver: #c2c2c2;
    --diamond: #cc23f9;
    --gold: #ff8a00;
    --purpur: #7762cd;
    --blueGradient: linear-gradient(73.15deg,
            #622ec6 11.62%,
            #5e67ee 50%,
            #71ced0 88.38%);
    --btnStroke: linear-gradient(90deg,
            #7762cd 0%,
            #53cbff 55.5%,
            #7762cd 100%);
    --blue: #1c3ac3;
    --darkPurpur: #36217d;
  }
  
  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  input,
  div {
    font-family: "Inter";
    color: white;
    font-weight: 400;
  }
  
  .Toastify__toast-theme--dark {
    background: var(--purple) !important;
  }
  
  .op80 {
    opacity: 0.8;
  }
  
  img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
  
  a {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-decoration: none;
  }
  
  h1 {
    font-size: 36px;
  }
  
  * {
    padding: 0;
    margin: 0;
  }
  
  body {
    position: relative;
    display: flex;
    max-height: 100%;
    overflow: hidden;
  }
  
  body.game-open > jdiv {
    display: none;
  }
  
  #root {
    display: flex;
    width: 100%;
  }
  
  .container-main {
    display: flex;
    position: relative;
    scroll-behavior: smooth;
    flex-direction: column;
    align-items: center;
    min-height: 100dvh;
    width: 100%;
    max-width: 100svw;
    background: var(--dark);
    overflow-x: hidden;
  }
  
  a.white-btn {
    position: relative;
    font-size: 14px;
    padding: 12px 16px;
    background: transparent;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    border: 1.3px solid var(--white);
    transition: 0.2s box-shadow, 0.3s transform;
  }
  
  a.white-btn:active {
    transform: scale(0.96);
  }
  
  a.white-btn:hover {
    box-shadow: 0px 0px 8px 0px rgb(255, 255, 255);
  }
  
  .btn {
    position: relative;
    font-size: 14px;
    text-align: center;
    padding: 12px 16px;
    border-radius: 10px;
    background: var(--blueGradient);
    box-sizing: border-box;
    transition: 0.2s box-shadow, 0.3s filter, 0.3s background;
  }
  
  .btn.check-btn {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn.check-btn > img {
    width: 24px;
    height: 24px;
  }
  
  .btn.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .btn:hover {
    background: linear-gradient(#6e37dd, #6e37dd);
  }
  
  .logo {
    width: 100px;
    height: 45px;
  }
  
  .logo > img {
    width: 100px;
    background-size: cover;
    height: 100px;
    transform: translateY(-28px);
  }
  
  .media-link {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .language-toggle {
    margin-right: 12px;
    position: relative;
  }
  
  .language-toggle__header {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  
  .language-toggle__header p {
    width: 28px;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .language-toggle__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 6px);
    background: var(--darkPurpur);
    border-radius: 12px;
    box-sizing: border-box;
    padding: 12px 0;
  }
  
  .language-toggle__body__item {
    padding-inline: 16px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    height: 26px;
    gap: 6px;
  }
  
  .language-toggle__body__item > p {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .language-toggle__body__item:hover {
    background: #5033aa;
  }
  
  .header {
    display: flex;
    max-width: 100%;
    position: relative;
    padding-right: 44px;
    min-height: 68px;
    padding-left: 60px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    -o-border-image: var(--blueGradient) 30;
    border-image: var(--blueGradient) 30;
    border-width: 0;
    border-bottom-width: 1px;
    border-style: solid;
  }
  
  .header__right {
    display: flex;
    margin-left: 28px;
    position: relative;
    justify-content: right;
    align-items: center;
  }
  
  .header__right__list {
    position: absolute;
    top: 100%;
    border: double 1px transparent;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 12px;
    right: 0;
    z-index: 100;
    box-sizing: border-box;
    padding: 6px 0;
    overflow: hidden;
  }
  
  .header__right__list__item {
    padding-inline: 16px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    height: 26px;
    gap: 6px;
  }
  
  .header__right__list__item > p {
    font-size: 14px;
    line-height: 14px;
  }
  
  .header__right__list__item > svg {
    height: 16px;
    width: 16px;
  }
  
  .header__right__list__item:hover {
    background: var(--blueGradient);
  }
  
  .header__right > .notifications-toggle {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: var(--blueGradient);
    transition: 0.1s opacity;
    border-radius: 50%;
    position: relative;
  }
  
  .notifications-counter {
    color: red;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    background-color: #622ec6;
    color: white;
    border-radius: 9999px;
  }
  
  .notifications-toggle .notifications-counter {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
  
  .header__right > .notifications-toggle > svg {
    width: 16px;
    height: 16px;
  }
  
  .header__right > .white-btn {
    margin-right: 12px;
  }
  
  .header__right__media-link {
    margin-right: 14px;
  }
  
  .header__left {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  .header__left__list {
    position: absolute;
    top: 100%;
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 12px;
    left: -9px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 6px 0;
  }
  
  .header__left__list__item {
    padding-inline: 16px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    height: 26px;
    gap: 6px;
  }
  
  .header__left__list__item:hover {
    background: var(--blueGradient);
  }
  
  .header__left__list__item > p {
    font-size: 12px;
    line-height: 14px;
  }
  
  .header__left__burger-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    z-index: 100;
    margin-right: 28px;
    height: 24px;
  }
  
  .ref-fields > div > nav {
    width: 100% !important;
    max-width: 600px;
  }
  
  .ref-fields > div > nav .copy-input > input {
    max-width: 100%;
    width: 100%;
  }
  
  .footer {
    display: flex;
    padding: 50px 125px 30px 125px;
    background: var(--dark);
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }
  
  .footer__right {
    display: flex;
    justify-content: right;
    align-items: flex-start;
    gap: 60px;
  }
  
  .footer__right > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .footer__right > div > h6,
  .footer__right > div a {
    font-size: 14px;
    line-height: 20px;
  }
  
  .footer__right > div > h6 {
    color: #7762cd;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .footer__left {
    display: flex;
    flex-direction: column;
  }
  
  .footer__left .age-block {
    display: flex;
    align-items: center;
    width: 42px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    height: 42px;
    opacity: 0.2;
    justify-content: center;
    border-radius: 50%;
    box-sizing: border-box;
    color: white;
    border: 2px solid white;
  }
  
  .footer__left .age-block p {
    font-size: 20px;
    font-family: "Montserrat";
  }
  
  .footer__left > span {
    opacity: 0.7;
    font-size: 12px;
    max-width: 318px;
    margin-bottom: 16px;
  }
  
  .footer__left > section {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
    align-items: center;
  }
  
  .footer__left > section > p {
    font-weight: 700;
    font-family: "Montserrat";
    font-size: 14px;
  }
  
  .footer__logo {
    margin-bottom: 3px;
    width: 81px;
    height: 49px;
  }
  
  .footer-providers {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #2a3261;
    gap: 45px;
    margin-top: 132px;
    align-items: center;
    padding: 40px 0;
  }
  
  .footer-providers > div {
    display: flex;
    flex-wrap: nowrap;
    gap: 45px;
  }
  
  .footer-providers > div > img {
    height: 30px;
    transition: 0.2s;
    pointer-events: all;
  }
  
  .container {
    display: flex;
    width: 100%;
    max-width: 960px;
    margin-inline: auto;
  }
  
  .main-first-screen {
    padding: 50px;
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(20, 2, 49, 0) 0%, rgba(70, 44, 150, 0.8) 70.29%, rgba(20, 2, 49, 0.76) 100%);
  }
  
  .main-first-screen .container {
    flex-direction: column;
  }
  
  .main-first-screen .swiper-selector {
    display: flex;
    gap: 7px;
  }
  
  .main-first-screen .swiper-selector > div {
    transition: 0.2s;
    cursor: pointer;
    width: 17px;
    height: 4px;
    border-radius: 3px;
    background: #532cc6;
  }
  
  .main-first-screen .swiper-selector > div.selected {
    width: 34px;
    background: #7762cd;
  }
  
  .main-first-screen .swiper {
    display: flex;
    max-width: 100%;
    position: relative;
    height: 348px;
  }
  
  .main-first-screen .swiper > .swiper__wrapper {
    width: 999999px;
    display: flex;
    transition: 0.5s;
    gap: 2040px;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
    position: relative;
    max-width: 100%;
    min-width: 960px;
    display: flex;
    justify-content: space-between;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div > h1 {
    font-weight: 800;
    font-size: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    margin-bottom: 22px;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div > h1 > span {
    font-weight: 800;
    background: var(--blueGradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div > p {
    margin-bottom: 16px;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div > ul {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    list-style-type: none;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div > ul > li {
    font-size: 14px;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > div > a {
    display: inline-flex;
    margin-top: 24px;
    pointer-events: all;
  }
  
  .main-first-screen .swiper > .swiper__wrapper > div > img {
    height: 280px;
    transform: scale(1.4);
    margin-top: 20px;
  }
  
  .main-games-list {
    padding-top: 57px;
    width: 100%;
  }
  
  .main-games-list .container {
    display: flex;
    flex-direction: column;
  }
  
  .main-games-list .container > a {
    align-self: center;
    width: 100%;
    margin-top: 24px;
  }
  
  .games__header {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }
  
  .games__header__left {
    display: flex;
    gap: 40px;
    align-items: flex-end;
    justify-content: left;
  }
  
  .games__header__left .selector {
    width: 250px;
  }
  
  .animation-Loading {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .animation-Loading > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .register-popup__form label.password-input {
    margin-bottom: 12px !important;
  }
  
  .disable-scroll {
    height: calc(var(--window-inner-height) - 1px);
    overflow: hidden;
  }
  
  .selector {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 250px;
  }
  
  .selector.colorized .selector__header {
    background: var(--blueGradient);
    border: 0;
  }
  
  .selector.colorized .selector__header > svg > path {
    stroke: white;
  }
  
  .selector.colorized .selector__header > p {
    color: white;
  }
  
  .selector.w-selector .selector__header {
    padding-right: 45px;
    padding-left: 40px;
  }
  
  .selector.w-selector .selector__header > p {
    color: white;
  }
  
  .selector.w-selector .selector__body {
    max-width: calc(100svw - 32px);
    width: 824px;
    max-height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  
  .selector.w-selector .selector__body > div {
    width: 205px;
  }
  
  .selector__header > svg {
    transition: 0.2s;
  }
  
  .selector.open > .selector__header > svg {
    transform: rotate(180deg);
  }
  
  .selector.open > .selector__body {
    pointer-events: all;
    opacity: 1;
  }
  
  .selector__body {
    pointer-events: none;
    opacity: 0;
    transition: 0.1s;
    flex-direction: column;
    box-sizing: border-box;
    flex-wrap: wrap;
    position: absolute;
    top: calc(100% + 12px);
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 100%;
    max-width: 100%;
    z-index: 50;
    padding: 12px 0;
  }
  
  .selector__body > div {
    padding-right: 6px;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    height: 36px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
  }
  
  .selector__body > div > a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .selector__body > div *:not(a) {
    pointer-events: none;
  }
  
  .selector__body > div:hover {
    background: #291561;
  }
  
  .selector__body > div:hover > p {
    color: white;
  }
  
  .selector__body > div > p {
    align-items: center;
    display: flex;
    gap: 6px;
    font-size: 15px;
    color: var(--purpur);
  }
  
  .selector__header {
    display: flex;
    position: relative;
    min-width: 100%;
    max-width: 100%;
    height: 42px;
    align-items: center;
    box-sizing: border-box;
    padding-inline: 8px;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  .selector__header > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .selector__header > p {
    align-items: center;
    display: flex;
    gap: 6px;
    font-size: 15px;
    color: var(--purpur);
  }
  
  .user-block {
    margin-left: 8px;
    display: flex;
    gap: 6px;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  .user-block > img {
    width: 38px;
    height: 38px;
  }
  
  .user-block > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .user-block > div > p {
    font-size: 14px;
    line-height: 14px;
  }
  
  .user-block > div > span {
    opacity: 0.5;
    font-size: 10px;
    line-height: 14px;
  }
  
  .user-block > svg {
    width: 17px;
    height: 17px;
  }
  
  .white-selector {
    margin-right: 8px;
  }
  
  .white-selector .selector__header {
    padding-inline: 8px;
    box-sizing: border-box;
    gap: 10px;
    min-width: 105px;
    opacity: 1;
    transition: 0.2s box-shadow;
  }
  
  .white-selector .selector__header > p {
    color: white;
  }
  
  .white-selector .selector__body {
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  .game-type-selector {
    margin-left: 12px;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    position: relative;
    list-style-type: none;
  }
  
  .game-type-selector > li {
    display: flex;
    align-items: center;
    min-width: 40px;
    justify-content: center;
    border-radius: 12px;
    transition: 0.3s;
    height: 40px;
    background: #8f47fe;
    cursor: pointer;
    position: relative;
  }
  
  .game-type-selector > li > svg {
    width: 18px;
  }
  
  .game-type-selector > li > * {
    pointer-events: none;
  }
  
  .game-type-selector > li:hover > span {
    display: flex;
  }
  
  .game-type-selector > li.active {
    min-width: 80px !important;
    background: var(--blueGradient);
  }
  
  .game-type-selector > li:hover,
  .game-type-selector > li.active {
    background-color: #8f47fe;
  }
  
  .game-type-selector > li > span {
    display: none;
    position: absolute;
    top: -44px;
    background: #8f47fe;
    border-radius: 6px;
    display: none;
    justify-content: center;
    transition: 0.3s;
    padding: 7px 6px;
    text-align: center;
  }
  
  .game-type-selector > li > span:after {
    top: 100%;
    display: inline-block;
    border: 9px solid transparent;
    border-top: 7px solid #8f47fe;
    content: "";
    position: absolute;
  }
  
  input[type=text],
  input[type=password],
  input[type=number] {
    position: relative;
    color: white;
    opacity: 0.8;
    max-width: 205px;
    font-family: "Montserrat";
    font-weight: 400;
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-sizing: border-box;
    outline: none;
    height: 42px;
    font-size: 15px;
    line-height: 15px;
    padding-inline: 10px;
  }
  
  input[type=text].error,
  input[type=password].error,
  input[type=number].error {
    border-color: #ac2929;
  }
  
  input[type=text]::-webkit-inner-spin-button,
  input[type=password]::-webkit-inner-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=text]::-moz-placeholder,
  input[type=password]::-moz-placeholder,
  input[type=number]::-moz-placeholder {
    color: white;
    opacity: 1;
    font-size: 15px;
    font-weight: 400;
  }
  
  input[type=text]::placeholder,
  input[type=password]::placeholder,
  input[type=number]::placeholder {
    color: white;
    opacity: 1;
    font-size: 15px;
    font-weight: 400;
  }
  
  .games__header__right > .search-input > input::-moz-placeholder {
    padding-left: 25px;
  }
  
  .games__header__right > .search-input > input::placeholder {
    padding-left: 25px;
  }
  
  .games__header__right > .search-input > input::-moz-placeholder,
  .games__header__right > .search-input > input:-moz-placeholder {
    padding-left: 25px;
  }
  
  .games__header__right > .search-input > input:-ms-input-placeholder {
    padding-left: 25px;
  }
  
  .games__header__right > .search-input::after {
    right: 48px;
  }
  
  .search-input {
    position: relative;
  }
  
  .search-input::after {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 10px;
    top: calc(50% - 8px);
    content: url("./../../public/images/search.svg");
  }
  
  .search-input > input {
    max-width: 250px !important;
  }
  
  .wheel-spin-block {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
  }
  
  .wheel-spin-block > div.coverage {
    top: 0;
    left: 0;
    position: absolute;
    width: 140%;
    height: 100%;
    left: -20%;
  }
  
  .wheel-spin-block > div.coverage > nav {
    background: rgba(0, 0, 0, 0.5960784314);
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .wheel-spin-block > div.coverage > img.bg {
    width: 100%;
    height: 100%;
    filter: grayscale(1);
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .wheel-spin-block__content {
    padding-top: 50px;
    position: relative;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .wheel-spin-block__content > h1 {
    font-family: "Inter";
    text-transform: uppercase;
    font-size: 79px;
    max-width: 654px;
    font-weight: 700;
    line-height: 80px;
    background: -webkit-linear-gradient(270deg, #ffffff 0%, #ffffff 60%, rgba(255, 255, 255, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    color: white;
  }
  
  .wheel-spin-block__content .wheel-block {
    min-height: calc(100svh - 308px);
    width: 100vw;
    height: 800px;
    position: relative;
    text-align: center;
    margin-top: 12px;
  }
  
  .wheel-spin-block__content .wheel-block .zeus {
    position: absolute;
    left: calc(50% - 152px - 385px);
    transform: scale(-1, 1);
    top: 54px;
    width: 385px;
    z-index: 11;
  }
  
  .wheel-spin-block__content .wheel-block .panda {
    position: absolute;
    width: 400px;
    top: -50px;
    left: calc(50% + 150px);
  }
  
  .wheel-spin-block__content .wheel-block .coins1 {
    position: absolute;
    bottom: 0;
    width: 502px;
    right: calc(50% + 430px);
  }
  
  .wheel-spin-block__content .wheel-block .coins2 {
    position: absolute;
    top: -322px;
    width: 502px;
    left: calc(50% + 345px);
  }
  
  .wheel-spin-block__content .wheel-block .dogs {
    z-index: 12;
    width: 558px;
    position: absolute;
    top: 418px;
    left: calc(50% + 130px);
  }
  
  .wheel-spin-block__content .wheel-block__spin {
    background: #5033aa;
    width: 233px;
    z-index: 10;
    position: absolute;
    top: 240px;
    left: calc(50% - 117px);
    border-radius: 50%;
    box-shadow: 0px 0px 21.9px 0px #774df9;
    box-shadow: 0px 0px 21.9px 0px #774df9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 233px;
  }
  
  .wheel-spin-block__content .wheel-block__spin > button {
    position: relative;
    outline: none;
    margin-top: 0;
    width: 210px;
    height: 210px;
    color: white;
    font-family: "Inter";
    z-index: 10;
    font-size: 32px;
    cursor: pointer;
    font-weight: 800;
    box-shadow: 0px 0px 26.8px 0px #774df9;
    background: #774df9;
    background: radial-gradient(50% 50% at 50% 50%, #774df9 0%, #6340d0 70%, #7851f3 85.5%, #9775ff 100%);
  }
  
  .wheel-spin-block__content .wheel-block__wheel {
    position: absolute;
    left: calc(50% - 54px);
    max-height: 900px;
    overflow: hidden;
    top: 0;
    z-index: 10;
    position: absolute;
    align-self: center;
    margin-top: -148px;
    margin-left: -452px;
  }
  
  .wheel-spin-block__content .wheel-block__wheel > svg {
    transition: 5s;
  }
  
  .wheel-spin-block__content .wheel-block__wheel > svg {
    width: 1000px;
    height: 1000px;
  }
  
  .wheel-spin-block__content .wheel-block__thumbler {
    position: relative;
    z-index: 12;
  }
  
  .wheel-spin-block__content button {
    background: #754bf7;
    font-size: 33px;
    padding: 10px;
    line-height: 40px;
    color: white;
    border-radius: 150px;
    border: 0;
    outline: none;
    font-family: "Inter";
    text-transform: uppercase;
    align-self: center;
    font-weight: 700;
    margin-top: 16px;
  }
  
  .wheel-spin-block .wheel-logo {
    position: absolute;
    top: 58px;
    width: 180px;
    height: 100px;
    left: 186px;
  }
  
  .wheel-spin-block .wheel-logo > img {
    width: 100%;
    transform: scale(1.4);
    height: 100%;
  }
  
  .games-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .games-list > div {
    display: grid;
    grid-gap: 12px 24px;
    max-width: 100%;
    grid-template-columns: repeat(6, 140px);
  }
  
  .games-list > div:nth-child(odd) > a:nth-child(9) {
    grid-column: 5/7;
  }
  
  .games-list > div:nth-child(even) > a:first-child {
    grid-column: 1/3;
  }
  
  .games-list > div:nth-child(odd) > a:nth-child(9),
  .games-list > div:nth-child(even) > a:first-child {
    grid-row: 1/3;
    /* 5 */
    max-width: 304px;
  }
  .games-list > div:nth-child(odd) > a:nth-child(9) .vpn,
  .games-list > div:nth-child(even) > a:first-child .vpn {
    font-size: 16px !important;
  }
  
  .games-list > div:nth-child(odd) > a:nth-child(9) > img,
  .games-list > div:nth-child(even) > a:first-child > img {
    height: 263px;
    margin-bottom: 24px;
  }
  
  .games-list > div:nth-child(odd) > a:nth-child(9):hover::after,
  .games-list > div:nth-child(even) > a:first-child:hover::after {
    top: 83px;
    width: 64px;
    height: 64px;
    background-image: url("../../public/images/play.svg");
    background-size: contain;
  }
  
  .games-list > div > a {
    position: relative;
    max-width: 140px;
    width: 100%;
    background: #1e143e;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .games-list > div > a:hover::after {
    position: absolute;
    top: 25px;
    width: 38px;
    height: 38px;
    content: "";
    background-image: url("../../public/images/play.svg");
  }
  
  .games-list > div > a:hover > img {
    opacity: 0.5;
  }
  
  .games-list > div > a > img {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    height: 107px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 19px;
  }
  
  .games-list > div > a > h4 {
    padding-inline: 10px;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    align-self: flex-start;
  }
  
  .games-list > div > a > span {
    padding-inline: 10px;
    opacity: 0.4;
    font-size: 10px;
    align-self: flex-start;
    line-height: 18px;
    margin-bottom: 6px;
  }
  
  .text-page-container {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 87px;
  }
  
  .text-page-container > h1 {
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  
  .text-page-container > h2 {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  
  .text-page-container > p {
    opacity: 0.8;
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 24px;
  }
  
  .text-page-container > p > ul {
    padding-left: 25px;
  }
  
  .text-page-container > p > a {
    font-weight: 500;
    color: #2b84e9;
    text-decoration: underline;
  }
  
  .text-page-container > h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .text-page-container > img {
    width: 261px;
    margin-bottom: 24px;
  }
  
  .faq-container > h1 {
    margin-bottom: 13px;
  }
  
  .faq-container > p {
    line-height: 1.2;
    max-width: 600px;
    font-size: 24px;
  }
  
  .faq-container > nav {
    display: flex;
    gap: 32px;
    flex-direction: column;
    width: 100%;
  }
  
  .faq-container > nav > div {
    display: flex;
    gap: 32px;
    flex-direction: column;
    width: 100%;
  }
  
  .faq-container > nav > div > span {
    align-self: flex-start;
    border-radius: 30px;
    background: transparent;
    box-sizing: border-box;
    padding: 16px 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 20px;
    line-height: 24px;
    border: double 1px transparent;
    border-radius: 12px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  .faq-container > nav > div.open > p {
    display: flex;
  }
  
  .faq-container > nav > div.ride > p {
    max-height: 9999px;
    transform: translateX(0);
  }
  
  .faq-container > nav > div > p {
    font-size: 20px;
    line-height: 24px;
    pointer-events: none;
    padding: 16px 32px;
    max-width: 762px;
    overflow: hidden;
    align-self: flex-end;
    border-radius: 12px;
    background: var(--blueGradient);
    box-sizing: border-box;
    max-height: 0;
    display: none;
    transform: translateX(100%);
    transition: 0.2s;
  }
  
  .Mobile-ProfileRight {
    display: none;
    width: 100svw;
    transform: translateX(120vw);
    transition: 0.3s transform;
    z-index: 1200;
    background: var(--dark);
    height: 100dvh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .Mobile-ProfileRight > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
  }
  
  .Mobile-ProfileRight.open {
    transform: translateX(0);
  }
  
  .Mobile-Selector {
    display: none;
    margin-bottom: 12px;
    gap: 8px;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .Mobile-Selector > a {
    padding: 8px 12px;
    color: white;
    opacity: 0.8;
    font-size: 12px;
    border-radius: 10px;
  }
  
  .Mobile-Selector > a.selected {
    background: var(--purple);
  }
  
  .NotFoundBlock .container {
    flex-direction: column;
    align-items: center;
    padding-top: 28px;
  }
  
  .NotFoundBlock .container h1 {
    font-size: 80px;
  }
  
  .NotFoundBlock .container h5 {
    font-size: 18px;
  }
  
  .NotFoundBlock .container a {
    margin-top: 24px;
  }
  
  .f50 {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    position: relative;
  }
  
  .card-in {
    border: double 1px transparent;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
    background-origin: border-box;
    box-sizing: border-box;
    background-clip: padding-box, border-box;
    border-radius: 12px;
    width: 100%;
    position: relative;
    max-width: 320px;
    padding: 20px;
  }
  
  .card-in > img {
    height: 24px;
    margin-bottom: 42px;
  }
  
  .card-in > span {
    font-size: 22px;
  }
  
  .card-in p {
    color: white;
  }
  
  .stroke-b-img {
    width: 48%;
    border: double 1px transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
    background-origin: border-box;
    box-sizing: border-box;
    background-clip: padding-box, border-box;
    min-height: 36px;
    padding: 8px 12px;
  }
  
  .stroke-b-img > img {
    height: 24px;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .copy-input {
    margin-bottom: 8px;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .copy-input > input {
    box-sizing: border-box;
    padding-right: 48px;
  }
  
  .copy-input > svg {
    position: absolute;
    cursor: pointer;
    right: 16px;
    width: 16px;
    height: 16px;
  }
  
  .copy-input > svg > path {
    pointer-events: none;
    fill: white;
  }
  
  .finish-registration {
    background: #221452;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 12px 24px;
  }
  
  .finish-registration__container {
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    align-items: center;
    margin: 0 auto;
    gap: 10px;
  }
  
  .finish-registration__container div {
    display: flex;
    align-items: center;
    gap: 8px;
    opacity: 0.9;
  }
  
  .finish-registration__container div p {
    font-size: 16px;
  }
  
  .finish-registration__container a {
    font-size: 14px;
    color: #95c8ff;
  }
  
  .Profile-Block {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-top: 65px;
    gap: 40px;
  }
  
  .Profile-Block .selector__body {
    max-height: 500px;
    overflow-y: auto;
  }
  
  .Profile-Block.centered {
    max-width: 960px;
    margin-inline: auto;
  }
  
  .Profile-Block__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .Profile-Block__container > p {
    opacity: 0.8;
    font-size: 12px;
    line-height: 15px;
    max-width: 100%;
  }
  
  .Profile-Block__container > span {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 6px;
  }
  
  .Profile-Block__container > span > p {
    opacity: 0.3;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .Profile-Block__fields {
    max-width: 960px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .Profile-Block__fields .deposit-bonus-select > div {
    width: 48px;
    height: 48px;
    border: double 2px transparent;
    border-radius: 8px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
    background-origin: border-box;
    box-sizing: border-box;
    background-clip: padding-box, border-box;
  }
  
  .Profile-Block__fields .deposit-bonus-select > div:last-child {
    background: transparent;
    border: 1px solid #df7373;
  }
  
  .Profile-Block__fields .deposit-bonus-select > div:last-child > svg > path {
    stroke: #df7373;
  }
  
  .Profile-Block__fields .deposit-bonus-select > div.selected {
    border: 0;
    background: var(--blueGradient);
  }
  
  .Profile-Block__fields .deposit-bonus-select > div.selected > svg > path {
    stroke: white;
  }
  
  .Profile-Block__fields .sales-list {
    gap: 12px !important;
  }
  
  .Profile-Block__fields .sales-list > div {
    width: 100% !important;
    height: auto !important;
    padding-bottom: 16px !important;
  }
  
  .Profile-Block__fields .sales-list > div > p {
    font-size: 16px;
    max-width: 200px;
  }
  
  .Profile-Block__fields .sales-list > div > h5 {
    margin-top: -9px;
    margin-bottom: 4px;
  }
  
  .Profile-Block__fields .sales-list > div > img {
    width: 126px !important;
    height: 126px !important;
  }
  
  .Profile-Block__fields .longtext {
    opacity: 0.8;
    font-size: 14px;
    line-height: 18px;
  }
  
  .Profile-Block__fields > h2 {
    font-size: 22px;
  }
  
  .Profile-Block__fields > .user-vip-levels {
    margin: 0 0 12px 0;
    max-width: 100%;
    overflow-x: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    overflow-x: auto;
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #bcb6eb;
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar-track:hover {
    background-color: #b8c0c2;
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar-track:active {
    background-color: #b8c0c2;
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--diamond);
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar-thumb:hover {
    background-color: var(--diamond);
  }
  
  .Profile-Block__fields > .user-vip-levels::-webkit-scrollbar-thumb:active {
    background-color: var(--diamond);
  }
  
  .Profile-Block__fields > .user-vip-levels > section {
    max-width: 198px;
  }
  
  .Profile-Block__fields > .user-vip-levels > section > input {
    width: 198px;
    top: 0;
    -webkit-appearance: none;
    vertical-align: middle;
    outline: none;
    border: none;
    padding: 0;
    background: none;
  }
  
  .Profile-Block__fields > .user-vip-levels > section > input::-webkit-slider-runnable-track {
    background-color: rgba(196, 196, 196, 0.5);
    height: 8px;
    border-radius: 6px;
    border: 1px solid transparent;
  }
  
  .Profile-Block__fields > .user-vip-levels > section > input::-webkit-slider-thumb {
    width: 30px;
    height: 8px;
    border-radius: 6px;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    top: -1px;
    z-index: 10;
    background: #5033aa;
  }
  
  .Profile-Block__fields > .user-vip-levels > div {
    align-self: flex-start;
    margin: 12px 0;
    transition: 0.1s;
    display: flex;
    position: relative;
    gap: 20px;
    max-width: 999999px;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div {
    max-width: 224px;
    width: 100%;
    min-width: 224px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #5033aa;
    border-radius: 10px;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > img {
    margin-top: -9px;
    margin-bottom: 4px;
    width: 48px;
    height: 48px;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > h4 {
    margin-bottom: 12px;
    font-size: 22px;
    line-height: 1;
    font-weight: 500;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    padding: 4px 0;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > nav > p {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.6;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > nav > span {
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > nav:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .Profile-Block__fields > .user-vip-levels > div > div > nav:nth-child(odd) {
    background: #6d36dd;
  }
  
  .Profile-Block__fields > .user-vip-profile {
    padding: 16px;
    background: #5033aa;
    display: flex;
    width: 224px;
    flex-direction: column;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 12px;
    gap: 20px;
  }
  
  .Profile-Block__fields > .user-vip-profile > div {
    display: flex;
    gap: 12px;
  }
  
  .Profile-Block__fields > .user-vip-profile > div > img {
    width: 48px;
    height: 48px;
  }
  
  .Profile-Block__fields > .user-vip-profile > div > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .Profile-Block__fields > .user-vip-profile > div > div > h6 {
    font-size: 22px;
    font-weight: 500;
  }
  
  .Profile-Block__fields > .user-vip-profile > div > div > p {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
  
  .Profile-Block__fields > .user-vip-profile > section {
    display: flex;
    flex-wrap: nowrap;
    gap: 6px;
  }
  
  .Profile-Block__fields > .user-vip-profile > section > img {
    width: 24px;
    height: 24px;
  }
  
  .Profile-Block__fields > .user-vip-profile > section > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .Profile-Block__fields > .user-vip-profile > section > div > span {
    opacity: 0.6;
    text-align: center;
    font-size: 10px;
    line-height: 10px;
  }
  
  .Profile-Block__fields > .user-vip-profile > section > div > nav {
    display: flex;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    width: 100%;
    height: 4px;
  }
  
  .Profile-Block__fields > .user-vip-profile > section > div > nav > span {
    transition: 0.2s;
    display: block;
    border-radius: 10px;
    height: 4px;
    background: var(--blueGradient);
  }
  
  .Profile-Block__fields > .game-history-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .Profile-Block__fields > .game-history-block > a {
    margin-top: 6px;
    align-self: center;
  }
  
  .Profile-Block__fields > .game-history-block > section {
    box-sizing: border-box;
    padding-inline: 16px;
    display: flex;
    margin-bottom: 10px;
  }
  
  .Profile-Block__fields > .game-history-block > section > p {
    width: 140px;
  }
  
  .Profile-Block__fields > .game-history-block > section > p:last-child {
    width: 103px;
  }
  
  .Profile-Block__fields > .game-history-block > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .Profile-Block__fields > .game-history-block > div > section {
    display: none;
  }
  
  .Profile-Block__fields > .game-history-block > div > button {
    cursor: pointer;
    background: #5033aa;
    border-radius: 15px;
    color: white;
    text-align: left;
    padding: 10px 15px;
    font-family: "Gotham";
    outline: none;
    border: 0;
    box-sizing: border-box;
    position: relative;
  }
  
  .Profile-Block__fields > .game-history-block > div > button.open + section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div {
    display: flex;
    background: #5033aa;
    border-radius: 15px;
    padding: 10px 16px;
    box-sizing: border-box;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div > div {
    display: flex;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div > nav {
    display: none;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div > div > p {
    width: 140px;
    font-size: 12px;
    opacity: 0.8;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div > div > p:last-child {
    width: 103px;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div > div > p.success {
    font-weight: 500;
    color: #37af35;
  }
  
  .Profile-Block__fields > .game-history-block > div > section > div > div > p.waiting {
    color: #73abdf;
    font-weight: 500;
  }
  
  .Profile-Block__fields > .notifications-block {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 560px;
  }
  
  .Profile-Block__fields > .notifications-block > div {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 12px 12px;
    background: var(--blueGradient);
    border-radius: 15px;
    max-width: 536px;
  }
  
  .Profile-Block__fields > .notifications-block > div.new {
    background: var(--blueGradient);
  }
  
  .Profile-Block__fields > .notifications-block > div > p {
    font-size: 12px;
  }
  
  .Profile-Block__fields > .notifications-block > div > h6 {
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    line-height: 12px;
  }
  
  .Profile-Block__fields .your-trades-header {
    margin-top: 20px;
  }
  
  .Profile-Block__fields > .your-trades-history {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .Profile-Block__fields > .your-trades-history > a {
    margin-top: 6px;
    align-self: center;
  }
  
  .Profile-Block__fields > .your-trades-history > section {
    box-sizing: border-box;
    padding-inline: 16px;
    display: flex;
  }
  
  .Profile-Block__fields > .your-trades-history > section > p {
    width: 140px;
    opacity: 0.8;
    font-family: "Montserrat";
  }
  
  .Profile-Block__fields > .your-trades-history > section > p:last-child {
    width: 103px;
  }
  
  .Profile-Block__fields > .your-trades-history > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div {
    display: flex;
    background: #5033aa;
    border-radius: 15px;
    padding: 10px 16px;
    box-sizing: border-box;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div > div {
    display: flex;
    align-items: center;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div > nav {
    display: none;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div > div > p {
    width: 140px;
    font-size: 12px;
    opacity: 0.8;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div > div > p:last-child {
    width: 103px;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div > div > p.success {
    align-self: flex-start;
    padding: 5px 10px;
    width: auto;
    font-weight: 500;
    border-radius: 10px;
    background: #46c01b;
    margin-left: -10px;
    color: white;
  }
  
  .Profile-Block__fields > .your-trades-history > div > div > div > p.waiting {
    width: auto;
    margin-left: -10px;
    padding: 5px 10px;
    color: white;
    border-radius: 10px;
    background: #40a3ff;
    font-weight: 500;
  }
  
  .Wallet-History {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .Wallet-History > section {
    box-sizing: border-box;
    padding-inline: 16px;
    display: flex;
    margin-bottom: 10px;
  }
  
  .Wallet-History > section > p {
    width: 140px;
  }
  
  .Wallet-History > section > p:last-child {
    width: 103px;
  }
  
  .Wallet-History > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .Wallet-History > div > div {
    display: flex;
    border: double 1px transparent;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    box-sizing: border-box;
    background-clip: padding-box, border-box;
    border-radius: 15px;
    padding: 7px 16px;
    box-sizing: border-box;
  }
  
  .Wallet-History > div > div > nav {
    display: none;
  }
  
  .Wallet-History > div > div > div {
    align-items: center;
    display: flex;
  }
  
  .Wallet-History > div > div > div > p {
    width: 140px;
    font-size: 12px;
    opacity: 0.8;
  }
  
  .Wallet-History > div > div > div > p:last-child {
    width: auto;
    padding: 5px 10px;
    margin-left: -10px;
    border-radius: 10px;
  }
  
  .Wallet-History > div > div > div > p.success {
    font-weight: 500;
    background: #46c01b;
    color: white;
  }
  
  .Wallet-History > div > div > div > p.error {
    color: white;
    background: #df7373;
  }
  
  .Wallet-History > div > div > div > p.waiting {
    color: #73abdf;
    font-weight: 500;
  }
  
  .Profile-Block__fields > a {
    align-self: flex-start;
    display: flex;
    margin-top: 20px;
    width: 205px;
    justify-content: center;
  }
  
  .Profile-Block__fields > .wallet-stat-block {
    display: flex;
    gap: 30px;
  }
  
  .Profile-Block__fields > .wallet-stat-block > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .Profile-Block__fields > .wallet-stat-block > div > p {
    font-size: 15px;
    line-height: 15px;
    opacity: 0.7;
    white-space: nowrap;
  }
  
  .Profile-Block__fields > .wallet-stat-block > div > h2 {
    font-size: 24px;
    white-space: nowrap;
    line-height: 24px;
  }
  
  .Profile-Block__fields > .sales-list {
    display: flex;
    gap: 10px;
  }
  
  .Profile-Block__fields > .sales-list > div {
    width: 205px;
    height: 205px;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    box-sizing: border-box;
    background: #5033aa;
    border-radius: 10px;
  }
  
  .Profile-Block__fields > .sales-list > div > img {
    width: 101px;
    height: 101px;
    align-self: center;
  }
  
  .Profile-Block__fields > .sales-list > div > h5 {
    color: #ffb905;
    font-family: "Montserrat";
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
  }
  
  .Profile-Block__fields > .sales-list > div > p {
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 16px;
  }
  
  .Profile-Block__fields > nav {
    display: flex;
    gap: 8px;
    position: relative;
    max-width: 440px;
    flex-direction: column;
  }
  
  .Profile-Block__fields > nav.your-trades-header > p {
    font-family: "Montserrat";
    margin-bottom: 8px;
  }
  
  .Profile-Block__fields > nav > .promocode-field {
    display: flex;
    gap: 6px;
    margin-bottom: 12px;
  }
  
  .Profile-Block__fields > nav > .reload-block {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
  }
  
  .Profile-Block__fields > nav > p > span.bronze {
    color: var(--bronze);
    font-weight: 500;
  }
  
  .Profile-Block__fields > nav > p > span.silver {
    color: var(--silver);
    font-weight: 500;
  }
  
  .Profile-Block__fields > nav > p > span.gold {
    font-weight: 500;
    color: var(--gold);
  }
  
  .Profile-Block__fields > nav > p > span.diamond {
    font-weight: 500;
    color: var(--diamond);
  }
  
  .Profile-Block__fields > nav > .trade-input-selector {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
  }
  
  .Profile-Block__fields > nav > .trade-input-selector + a {
    margin-top: 4px;
    align-self: flex-start;
    width: 205px;
  }
  
  .Profile-Block__fields > nav > .selector {
    margin-bottom: 12px;
  }
  
  .Profile-Block__fields > nav > p {
    opacity: 0.8;
  }
  
  .Profile-Block__fields > nav > span {
    opacity: 0.5;
    font-size: 12px;
    line-height: 14px;
    max-width: 100%;
  }
  
  .Profile-Block__fields > nav > input {
    max-width: 100%;
  }
  
  .Profile-Block__fields > div {
    display: flex;
    gap: 30px;
  }
  
  .Profile-Block__fields > div > nav {
    width: 205px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  
  .Profile-Block__fields > div > nav > .ref-cont {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .Profile-Block__fields > div > nav .button-select_r {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
  
  .Profile-Block__fields > div > nav .button-select_r > a {
    padding: 8px 12px;
    border-radius: 12px;
    white-space: nowrap;
  }
  
  .Profile-Block__fields > div > nav .button-select_r > a.selected {
    background: var(--purple);
  }
  
  .referrals-wrap {
    width: 90%;
  }
  
  .refStat {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: min(330px, 100%);
    width: 90%;
    margin-bottom: 12px;
  }
  
  .refStat > div {
    align-items: center;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    border: double 1px transparent;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    display: flex;
    gap: 8px;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 15px;
  }
  
  .refStat > div > div {
    display: flex;
    gap: 8px;
  }
  
  .refStat > div > div > span {
    opacity: 0.7;
    font-size: 14px;
  }
  
  .refStat > div > p {
    position: relative;
    font-size: 18px;
    line-height: 14px;
  }
  
  .referrals {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: min(330px, 100%);
  }
  
  .referrals > p {
    width: 100%;
    border: double 1px transparent;
    border-radius: 6px;
    box-sizing: border-box;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    text-align: center;
    padding: 14px;
    border-radius: 15px;
    font-size: 14px;
    opacity: 0.8;
  }
  
  .referrals > div {
    width: 100%;
    padding: 14px;
    box-sizing: border-box;
    gap: 6px;
    align-self: center;
    border: double 1px transparent;
    border-radius: 6px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
  }
  
  .referrals > div > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  
  .referrals > div > span {
    font-size: 14px;
    font-weight: 500;
    color: #22c02f;
  }
  
  .Profile-Block__fields > div > nav > .selector {
    margin-bottom: 12px;
  }
  
  .Profile-Block__fields > div > nav > .selector.nomargin {
    margin-bottom: 0;
  }
  
  .Profile-Block__fields > div > nav > span {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 4px;
  }
  
  .Profile-Block__fields > div > nav > img.qr-code-2FA {
    width: 148px;
    height: 158px;
    border-radius: 15px;
  }
  
  .Profile-Block__fields > div > nav > p {
    font-size: 14px;
    opacity: 0.8;
  }
  
  .Profile-Block__fields > div > nav > p > span.red_span {
    font-size: 12px;
    color: #a61c1c;
    font-weight: 500;
  }
  
  .Profile-Block__fields > div > nav > p > span.green_span {
    font-size: 12px;
    color: #22c02f;
    font-weight: 500;
  }
  
  .toggle-switch {
    width: 41px;
    display: flex;
    position: relative;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .toggle-switch span {
    width: 100%;
    transition: 0.3s;
    height: 22px;
    box-sizing: border-box;
    position: relative;
    border: double 1px transparent;
    border-radius: 6px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 6px;
  }
  
  .toggle-switch span::after {
    content: "";
    position: absolute;
    top: 3px;
    width: 13px;
    height: 13px;
    transition: 0.2s;
    left: 3px;
    border-radius: 3px;
    background: var(--purpur);
  }
  
  .toggle-switch input:checked + span {
    background: var(--blueGradient);
    border: 1px solid #5033aa;
  }
  
  .toggle-switch input:checked + span::after {
    left: 21px;
    background: white;
  }
  
  .male-selector {
    display: flex;
    gap: 10px;
  }
  
  .male-selector > label {
    display: flex;
    gap: 7px;
    align-items: center;
  }
  
  label {
    cursor: pointer;
  }
  
  input[type=checkbox] {
    position: relative;
    box-sizing: border-box;
    outline: none;
    min-width: 22px;
    height: 22px;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border: double 1px transparent;
    border-radius: 6px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  input[type=checkbox]:checked {
    background: var(--purpur);
  }
  
  input[type=checkbox]:checked::after {
    width: 14px;
    height: 14px;
    content: url("./../../public/images/check.svg");
    position: absolute;
    left: 3px;
    top: 3px;
  }
  
  .profile-menu-list {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    gap: 6px;
  }
  
  .profile-menu-list > a {
    display: flex;
    padding: 12px 8px;
    border-radius: 10px;
    gap: 6px;
    align-items: center;
    align-self: stretch;
    justify-content: left;
  }
  
  .profile-menu-list > a > p {
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 500;
  }
  
  .profile-menu-list > a:hover {
    background: rgba(163, 96, 205, 0.5019607843);
  }
  
  .profile-menu-list > a.selected {
    background: var(--blueGradient);
  }
  
  .profile-menu-list > section {
    gap: 11px;
    padding: 5px 0 5px 24px;
    display: flex;
    flex-direction: column;
  }
  
  .profile-menu-list > section > a {
    font-family: "Montserrat";
    font-size: 12px;
  }
  
  .profile-menu-list > section > a.selected {
    background: var(--blueGradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .Profile-Block__Verification-Popup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px 35px 20px 35px;
    border-radius: 30px;
    background: var(--dark);
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    max-width: 366px;
    margin-top: -60px;
    border: double 1px transparent;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    z-index: 10;
    cursor: auto;
  }
  
  .Profile-Block__Verification-Popup > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 15;
    cursor: pointer;
  }
  
  .Profile-Block__Verification-Popup > section {
    margin-top: 24px;
    margin-bottom: 40px;
    border: 2px solid #7762cd;
    border-radius: 5px;
    box-sizing: border-box;
    width: 233px;
    height: 113px;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .Profile-Block__Verification-Popup > section > label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .Profile-Block__Verification-Popup > section > label > input {
    display: none;
  }
  
  .Profile-Block__Verification-Popup > section > label > p {
    opacity: 0.8;
    max-width: 120px;
    font-size: 12px;
  }
  
  .Profile-Block__Verification-Popup > section > label > p > a {
    font-weight: 500;
    color: var(--purpur);
  }
  
  .Profile-Block__Verification-Popup > a {
    width: 100%;
  }
  
  .Profile-Block__Verification-Popup > h2 {
    margin-bottom: 12px;
    font-size: 24px;
  }
  
  .Profile-Block__Verification-Popup > span {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.8;
    margin-bottom: 14px;
  }
  
  .Profile-Block__Verification-Popup > p {
    font-size: 15px;
  }
  
  .Profile-Block__Verification-Popup > p > a {
    font-weight: 500;
    color: var(--purpur);
  }
  
  .Profile-Block__Verification-Popup__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7176470588);
    display: flex;
    align-items: center;
  }
  
  .password-input {
    position: relative;
  }
  
  .password-input > input {
    max-width: 100%;
    width: 100%;
  }
  
  .password-input > input[type=password] + a > svg:first-child {
    display: none;
  }
  
  .password-input > input[type=password] + a > svg:last-child {
    display: block;
  }
  
  .password-input > a {
    right: 8px;
    display: flex;
    align-items: center;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    position: absolute;
  }
  
  .password-input > a > svg {
    pointer-events: none;
  }
  
  .password-input > a > svg:last-child {
    display: none;
  }
  
  .password-input > a > svg {
    width: 16px;
    height: 16px;
  }
  
  .left-right-select {
    width: 420px;
    max-width: calc(100svw - 32px);
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
  
  .left-right-select.y > div {
    min-width: 80px;
    flex-direction: column;
  }
  
  .left-right-select > div {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 6px;
    border: 1px solid #8c53ff;
    border-radius: 5px;
    cursor: pointer;
    width: calc(50% - 2.5px);
  }
  
  .left-right-select > div > div {
    display: flex;
    gap: 5px;
    max-width: 140px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .left-right-select > div > div > img {
    height: 22px;
  }
  
  .left-right-select > div > div > svg {
    width: 22px;
    height: 22px;
  }
  
  .left-right-select > div > p {
    color: white;
  }
  
  .left-right-select > div > svg > g > path {
    fill: white;
  }
  
  .left-right-select > div.selected {
    background: #8c53ff;
  }
  
  .left-right-select > div > svg,
  .left-right-select > div > div > svg,
  .left-right-select > div div > img,
  .left-right-select > div > div {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .left-right-select > div > svg > g > path {
    fill: white;
  }
  
  .left-right-select > div > p {
    font-size: 12px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
    color: white;
  }
  
  .popup--fs .left-right-select {
    max-width: 100%;
  }
  
  .deposit-bonus-select {
    display: flex;
    flex-wrap: nowrap;
    gap: 6px;
  }
  
  .deposit-bonus-select > div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background: rgba(80, 51, 170, 0.6);
  }
  
  .deposit-bonus-select > div > svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .deposit-bonus-select > div > svg > path {
    stroke: white;
  }
  
  .deposit-bonus-select > div:last-child {
    background: rgba(223, 115, 115, 0.6980392157);
  }
  
  .deposit-bonus-select > div.selected {
    background: #8c53ff;
  }
  
  .deposit-bonus-select > div.selected:last-child {
    background: #df7373;
  }
  
  .wager--form {
    padding: 24px;
    position: relative;
    border-radius: 16px;
    background: #1e143e;
    width: 380px;
    max-width: 100%;
  }
  
  .wager--form > h3 {
    margin-bottom: 32px;
  }
  
  .wager--form > a {
    margin-top: 22px;
    display: block;
  }
  
  .popup--fs {
    position: fixed;
    display: none;
    align-items: center;
    width: 100svw;
    height: 100dvh;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7019607843);
    z-index: 1000;
  }
  
  .popup--fs.open {
    display: flex;
  }
  
  .popup--fs > div * {
    font-family: "Montserrat";
  }
  
  .popup--fs > div > h1 {
    font-family: "Montserrat";
    font-weight: 500;
  }
  
  .popup--fs .depositPopup__form {
    padding-top: 50px;
  }
  
  .popup--fs .depositPopup__form a:last-child {
    border: double 1px transparent;
    box-sizing: border-box;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  .popup--fs .register-popup__form,
  .popup--fs .login-popup__form,
  .popup--fs .send-popup__form,
  .popup--fs .depositPopup__form {
    position: relative;
    box-sizing: border-box;
    padding: 40px 40px 20px 40px;
    background: var(--dark);
    border-radius: 20px;
    border: 2px solid var(--purpur);
    box-shadow: 0px 0px 20px 0px rgb(119, 98, 205);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
  }
  
  .popup--fs .register-popup__form > svg,
  .popup--fs .login-popup__form > svg,
  .popup--fs .send-popup__form > svg,
  .popup--fs .depositPopup__form > svg {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 50px;
    right: 45px;
  }
  
  .popup--fs .register-popup__form > svg > path,
  .popup--fs .login-popup__form > svg > path,
  .popup--fs .send-popup__form > svg > path,
  .popup--fs .depositPopup__form > svg > path {
    stroke: #8c53ff;
  }
  
  .popup--fs .register-popup__form > h1,
  .popup--fs .login-popup__form > h1,
  .popup--fs .send-popup__form > h1,
  .popup--fs .depositPopup__form > h1 {
    margin-bottom: 40px;
  }
  
  .popup--fs .register-popup__form > div,
  .popup--fs .login-popup__form > div,
  .popup--fs .send-popup__form > div,
  .popup--fs .depositPopup__form > div {
    display: flex;
    gap: 30px;
  }
  
  .popup--fs .register-popup__form > div > nav,
  .popup--fs .login-popup__form > div > nav,
  .popup--fs .send-popup__form > div > nav,
  .popup--fs .depositPopup__form > div > nav {
    width: 402px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  
  .popup--fs .register-popup__form > div > nav > label,
  .popup--fs .login-popup__form > div > nav > label,
  .popup--fs .send-popup__form > div > nav > label,
  .popup--fs .depositPopup__form > div > nav > label {
    margin-top: 4px;
    display: flex;
    gap: 7px;
    align-items: center;
  }
  
  .popup--fs .register-popup__form > div > nav > label > p,
  .popup--fs .login-popup__form > div > nav > label > p,
  .popup--fs .send-popup__form > div > nav > label > p,
  .popup--fs .depositPopup__form > div > nav > label > p {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .popup--fs .register-popup__form > div > nav > label > p > a,
  .popup--fs .login-popup__form > div > nav > label > p > a,
  .popup--fs .send-popup__form > div > nav > label > p > a,
  .popup--fs .depositPopup__form > div > nav > label > p > a {
    color: var(--blue);
    font-weight: 500;
    text-decoration: underline;
  }
  
  .popup--fs .register-popup__form > div > nav > input,
  .popup--fs .register-popup__form > div > nav > label,
  .popup--fs .login-popup__form > div > nav > input,
  .popup--fs .login-popup__form > div > nav > label,
  .popup--fs .send-popup__form > div > nav > input,
  .popup--fs .send-popup__form > div > nav > label,
  .popup--fs .depositPopup__form > div > nav > input,
  .popup--fs .depositPopup__form > div > nav > label {
    margin-bottom: 12px;
  }
  
  .popup--fs .register-popup__form > div > nav > .selector,
  .popup--fs .login-popup__form > div > nav > .selector,
  .popup--fs .send-popup__form > div > nav > .selector,
  .popup--fs .depositPopup__form > div > nav > .selector {
    margin-bottom: 12px;
  }
  
  .popup--fs .register-popup__form > div > nav > .selector.nomargin,
  .popup--fs .login-popup__form > div > nav > .selector.nomargin,
  .popup--fs .send-popup__form > div > nav > .selector.nomargin,
  .popup--fs .depositPopup__form > div > nav > .selector.nomargin {
    margin-bottom: 0;
  }
  
  .popup--fs .register-popup__form > div > nav > span,
  .popup--fs .login-popup__form > div > nav > span,
  .popup--fs .send-popup__form > div > nav > span,
  .popup--fs .depositPopup__form > div > nav > span {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 4px;
  }
  
  .popup--fs .register-popup__form > div > nav > img.qr-code-2FA,
  .popup--fs .login-popup__form > div > nav > img.qr-code-2FA,
  .popup--fs .send-popup__form > div > nav > img.qr-code-2FA,
  .popup--fs .depositPopup__form > div > nav > img.qr-code-2FA {
    width: 148px;
    height: 158px;
    border-radius: 15px;
  }
  
  .popup--fs .register-popup__form > div > nav > input,
  .popup--fs .login-popup__form > div > nav > input,
  .popup--fs .send-popup__form > div > nav > input,
  .popup--fs .depositPopup__form > div > nav > input {
    width: 100%;
    max-width: 100%;
  }
  
  .popup--fs .register-popup__form > div > nav > a.forgot,
  .popup--fs .login-popup__form > div > nav > a.forgot,
  .popup--fs .send-popup__form > div > nav > a.forgot,
  .popup--fs .depositPopup__form > div > nav > a.forgot {
    align-self: flex-end;
    font-size: 12px;
    opacity: 0.8;
  }
  
  .popup--fs .register-popup__form > div > nav .password-input,
  .popup--fs .login-popup__form > div > nav .password-input,
  .popup--fs .send-popup__form > div > nav .password-input,
  .popup--fs .depositPopup__form > div > nav .password-input {
    margin-bottom: 0;
  }
  
  .popup--fs .register-popup__form > div > nav > a:last-child,
  .popup--fs .login-popup__form > div > nav > a:last-child,
  .popup--fs .send-popup__form > div > nav > a:last-child,
  .popup--fs .depositPopup__form > div > nav > a:last-child {
    margin-top: 16px;
  }
  
  .popup--fs .register-popup__form > div > nav > .selector,
  .popup--fs .login-popup__form > div > nav > .selector,
  .popup--fs .send-popup__form > div > nav > .selector,
  .popup--fs .depositPopup__form > div > nav > .selector {
    max-width: 100%;
  }
  
  .popup--fs .register-popup__form > div > nav > p,
  .popup--fs .login-popup__form > div > nav > p,
  .popup--fs .send-popup__form > div > nav > p,
  .popup--fs .depositPopup__form > div > nav > p {
    opacity: 0.8;
  }
  
  .popup--fs .register-popup__form > div > nav > p > span.red_span,
  .popup--fs .login-popup__form > div > nav > p > span.red_span,
  .popup--fs .send-popup__form > div > nav > p > span.red_span,
  .popup--fs .depositPopup__form > div > nav > p > span.red_span {
    font-size: 12px;
    color: #a61c1c;
    font-weight: 500;
  }
  
  .popup--fs .register-popup__form > div > nav > p > span.green_span,
  .popup--fs .login-popup__form > div > nav > p > span.green_span,
  .popup--fs .send-popup__form > div > nav > p > span.green_span,
  .popup--fs .depositPopup__form > div > nav > p > span.green_span {
    font-size: 12px;
    color: #22c02f;
    font-weight: 500;
  }
  
  .login-popup__form > div > nav > label {
    margin-top: 0 !important;
  }
  
  .MobileSupport {
    z-index: 100;
    box-shadow: 0 0 6px rgb(119, 98, 205);
    display: none;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    position: fixed;
    bottom: 24px;
    right: 36px;
    width: 64px;
    height: 64px;
    background: var(--purple);
    border-radius: 50%;
    transition: 0.2s box-shadow;
  }
  
  .MobileSupport:hover {
    box-shadow: 0 0 12px rgb(119, 98, 205);
  }
  
  .MobileSupport > svg {
    width: 36px;
    transform: scaleX(-1);
    height: 36px;
  }
  
  .popup--fs .login-popup__form > div {
    flex-direction: column;
  }
  
  .close-popup-svg {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 20px;
    right: 20px;
  }
  
  .close-popup-svg > path {
    stroke: #8c53ff;
  }
  
  .deposit-popup__form {
    position: relative;
    box-sizing: border-box;
    padding: 50px 40px 20px 40px;
    background: var(--dark);
    border-radius: 20px;
    border: 2px solid var(--purpur);
    box-shadow: 0px 0px 20px 0px rgb(119, 98, 205);
  }
  
  .deposit-popup__form input {
    height: 48px;
    padding-inline: 40px;
  }
  
  .deposit-popup__form .left-right-select > div {
    padding: 13px 20px;
    border-radius: 10px;
  }
  
  .deposit-popup__form .selector .selector__header {
    height: 48px;
    padding-inline: 40px;
  }
  
  .deposit-popup__form .selector .selector__header > p {
    font-size: 15px;
  }
  
  .deposit-popup__form .selector .selector__body > div {
    padding-inline: 40px;
  }
  
  .deposit-popup__form > svg {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 20px;
    right: 20px;
  }
  
  .deposit-popup__form > svg > path {
    stroke: #8c53ff;
  }
  
  .deposit-popup__form > h1 {
    margin-bottom: 40px;
  }
  
  .deposit-popup__form input {
    border: 1px solid #7762cd;
  }
  
  .deposit-popup__form > div {
    display: flex;
    gap: 30px;
  }
  
  .deposit-popup__form > div > nav {
    width: 402px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  
  .deposit-popup__form > div > nav > .selector {
    margin-bottom: 12px;
  }
  
  .deposit-popup__form > div > nav > .selector.nomargin {
    margin-bottom: 0;
  }
  
  .deposit-popup__form > div > nav > span {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 4px;
  }
  
  .deposit-popup__form > div > nav > img.qr-code-2FA {
    width: 148px;
    height: 158px;
    border-radius: 15px;
  }
  
  .deposit-popup__form > div > nav > input {
    width: 100%;
    max-width: 100%;
  }
  
  .deposit-popup__form > div > nav > a {
    margin-top: 16px;
  }
  
  .deposit-popup__form > div > nav > .selector {
    max-width: 100%;
  }
  
  .deposit-popup__form > div > nav > p {
    font-family: "Montserrat";
    opacity: 0.8;
  }
  
  .deposit-popup__form > div > nav > p > span.red_span {
    font-size: 12px;
    color: #a61c1c;
    font-weight: 500;
  }
  
  .deposit-popup__form > div > nav > p > span.green_span {
    font-size: 12px;
    color: #22c02f;
    font-weight: 500;
  }
  
  .bonuses-block .sales-list {
    max-width: 663px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }
  
  .bonuses-block .sales-list > div {
    width: 205px;
    height: 205px;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    box-sizing: border-box;
    background: #5033aa;
    border-radius: 10px;
  }
  
  .bonuses-block .sales-list > div > img {
    width: 101px;
    height: 101px;
    align-self: center;
  }
  
  .bonuses-block .sales-list > div > h5 {
    color: #ffb905;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
  }
  
  .bonuses-block .sales-list > div > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  
  .bonuses-block > div:first-child {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    max-width: 100%;
    gap: 40px;
  }
  
  .bonuses-block > div:first-child > img {
    width: 320px;
  }
  
  .bonuses-block > div:first-child > nav {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  
  .bonuses-block > section {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .bonuses-block > section > h2 {
    font-size: 24px;
  }
  
  .bonuses-block > section > a {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 12px;
  }
  
  .payments-block > section {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .payments-block > section > h2 {
    font-size: 24px;
  }
  
  .payments-block > section > a {
    font-size: 14px;
    line-height: 12px;
    padding: 12px 35px;
  }
  
  .payments-block > div:first-child {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    max-width: 100%;
    gap: 40px;
  }
  
  .payments-block > div:first-child > nav > p {
    font-size: 14px;
  }
  
  .payments-block > div:first-child > img {
    width: 320px;
  }
  
  .payments-block > div:first-child > nav {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  
  .payments-block > .deposit-types-block {
    margin-bottom: 24px;
  }
  
  .payments-block > .deposit-types-block,
  .payments-block .withdraw-types-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .payments-block > .deposit-types-block > a,
  .payments-block .withdraw-types-block > a {
    margin-top: 6px;
    align-self: center;
  }
  
  .payments-block > .deposit-types-block > section,
  .payments-block .withdraw-types-block > section {
    box-sizing: border-box;
    padding-inline: 16px;
    display: flex;
    margin-bottom: 2px;
  }
  
  .payments-block > .deposit-types-block > section > p,
  .payments-block .withdraw-types-block > section > p {
    width: 140px;
  }
  
  .payments-block > .deposit-types-block > section > p:nth-child(4),
  .payments-block .withdraw-types-block > section > p:nth-child(4) {
    width: 197px;
  }
  
  .payments-block > .deposit-types-block > section > p:last-child,
  .payments-block .withdraw-types-block > section > p:last-child {
    width: 183px;
  }
  
  .payments-block > .deposit-types-block > div,
  .payments-block .withdraw-types-block > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .payments-block > .deposit-types-block > div > div,
  .payments-block .withdraw-types-block > div > div {
    display: flex;
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 15px;
    padding: 10px 16px;
    box-sizing: border-box;
  }
  
  .payments-block > .deposit-types-block > div > div > nav,
  .payments-block .withdraw-types-block > div > div > nav {
    display: none;
  }
  
  .payments-block > .deposit-types-block > div > div > div,
  .payments-block .withdraw-types-block > div > div > div {
    display: flex;
  }
  
  .payments-block > .deposit-types-block > div > div > div > p,
  .payments-block .withdraw-types-block > div > div > div > p {
    width: 140px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0.8;
  }
  
  .payments-block > .deposit-types-block > div > div > div > p:last-child,
  .payments-block .withdraw-types-block > div > div > div > p:last-child {
    width: 183px;
  }
  
  .payments-block > .deposit-types-block > div > div > div > p:nth-child(4),
  .payments-block .withdraw-types-block > div > div > div > p:nth-child(4) {
    width: 197px;
  }
  
  .payments-block > .deposit-types-block > div > div > div > p.success,
  .payments-block .withdraw-types-block > div > div > div > p.success {
    font-weight: 500;
    color: #37af35;
  }
  
  .payments-block > .deposit-types-block > div > div > div > p.waiting,
  .payments-block .withdraw-types-block > div > div > div > p.waiting {
    color: #73abdf;
    font-weight: 500;
  }
  
  @media (max-width: 1180px) {
    .footer {
      padding-inline: calc(50% - 440px);
    }
    .footer__right {
      gap: 65px;
    }
  }
  .back--button {
    background: #182533;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    position: fixed;
    top: 25px;
    left: 25px;
    z-index: 10001;
    align-items: center;
    justify-content: center;
  }
  
  .back--button > svg {
    width: 32px;
    height: 32px;
  }
  
  .percent-win--modal--in {
    padding: 10px;
    background: rgba(255, 255, 255, 0.1019607843);
    border-radius: 14px;
  }
  
  @media (max-width: 960px) {
    .Profile-Block__container h1 {
      font-size: 24px;
    }
    .wheel-spin-block__content .wheel-block .dogs {
      top: 315px;
    }
    .wheel-spin-block {
      overflow: hidden;
      max-height: 100svh;
    }
    .wheel-spin-block__content .wheel-block {
      height: 500px !important;
    }
    .coins1 {
      top: -553px !important;
      left: auto !important;
      right: calc(50% - 100px) !important;
      transform: scale(0.45);
    }
    .coins2 {
      left: auto !important;
      right: -160px !important;
      top: -460px !important;
      transform: rotate(27deg) scale(0.45);
    }
    .wheel-spin-block__content .wheel-block .panda {
      display: none;
    }
    .wheel-spin-block__content .wheel-block .zeus {
      left: -143px;
      width: 283px;
      top: 250px;
    }
    .wheel-block__spin {
      width: 142px !important;
      height: 142px !important;
    }
    .wheel-block__spin > button {
      width: 127px !important;
      height: 127px !important;
      margin-top: 0 !important;
    }
    .wheel-spin-block__content .wheel-block__wheel > svg {
      width: 600px;
      height: 600px;
      margin-left: 205px !important;
      margin-top: 50px !important;
    }
    .wheel-spin-block__content .dogs {
      width: 348px !important;
      left: calc(50% + 42px) !important;
    }
    .wheel-spin-block__content .wheel-block__spin {
      top: 130px;
      left: calc(50% - 71px);
    }
    .wheel-block__thumbler {
      width: 63px;
      height: 54px;
    }
    .wheel-spin-block__content > button {
      margin-top: 14px !important;
      margin-bottom: 45px;
    }
    .wheel-logo {
      width: 128px !important;
      left: calc(50% - 64px) !important;
      top: 57px !important;
      height: 78px !important;
    }
    .wheel-spin-block__content {
      padding-top: 150px;
    }
    .wheel-spin-block__content > h1 {
      font-size: 28px !important;
      line-height: 32px !important;
      max-width: 230px !important;
    }
    .wheel-spin-block__content button {
      font-size: 16px !important;
      padding: 12px 20px !important;
      line-height: 1 !important;
    }
  }
  @media (max-width: 960px) and (max-height: 850px) {
    .wheel-logo {
      width: 90px !important;
      height: 55px !important;
      left: calc(50% - 45px) !important;
      top: 15px !important;
    }
    .wheel-spin-block {
      min-height: 100svh !important;
    }
    .wheel-spin-block__content .wheel-block .zeus {
      top: 180px !important;
    }
    .coins2 {
      top: -380px !important;
    }
    .wheel-form {
      max-height: 100% !important;
    }
    .coins1 {
      top: -443px !important;
    }
    .wheel-spin-block__content .wheel-block .dogs {
      top: 230px;
    }
    .wheel-spin-block__content {
      padding-top: 80px !important;
    }
    .wheel-spin-block__content > h1 {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    .wheel-spin-block__content > button {
      margin-top: 8px !important;
      margin-bottom: 10px !important;
    }
  }
  @media (max-width: 960px) {
    .button-select_r {
      flex-direction: column;
      text-align: center;
    }
    .Wallet-History > div > div > div {
      align-items: flex-start;
    }
    .Wallet-History > div > div > div > p:last-child {
      margin-left: 0;
    }
    .footer {
      padding-top: 65px !important;
    }
    .footer__right a {
      font-size: 12px !important;
    }
    .selector__header {
      padding-inline: 8px;
    }
    .search-input > input::-moz-placeholder {
      padding-left: 0;
    }
    .search-input > input::placeholder,
  .search-input > input::-moz-placeholder,
  .search-input > input::-ms-input-placeholder,
  .search-input > input:-moz-placeholder {
      padding-left: 0;
    }
    .search-input::after {
      right: 10px;
    }
    .popup--fs {
      background: var(--dark);
      width: 100%;
      align-items: flex-start;
    }
    .popup--fs .send-popup__form {
      width: 100%;
      height: 100dvh;
      border: 0;
      border-radius: 0;
    }
    .popup--fs > div {
      top: 0;
      max-height: calc(100% - 48px);
      overflow-y: auto;
    }
    .popup--fs .register-popup__form,
  .popup--fs .send-popup__form,
  .popup--fs .login-popup__form {
      padding-inline: 16px;
    }
    .header__right__list {
      display: none;
    }
    .Mobile-ProfileRight {
      display: flex;
    }
    .Mobile-ProfileRight > svg {
      cursor: pointer;
    }
    .Mobile-ProfileRight > div {
      margin-inline: auto;
      max-width: calc(100% - 32px);
      width: 100%;
      padding-top: 84px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .Mobile-ProfileRight > div > a {
      padding: 0;
    }
    .Mobile-ProfileRight > div > a > * {
      pointer-events: none;
    }
    .Mobile-ProfileRight > div > a:hover {
      background: transparent !important;
    }
    .Mobile-Selector {
      display: flex;
    }
    .Mobile-Selector > a {
      white-space: nowrap;
    }
    .games__header {
      gap: 12px;
      flex-direction: column-reverse;
    }
    .games__header__right {
      width: 100%;
    }
    .games__header .search-input {
      width: 100%;
    }
    .games__header .search-input > input {
      max-width: 100% !important;
      width: 100% !important;
    }
    .games__header__left {
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
    .games__header__left .game-type-selector {
      overflow-x: auto;
      margin-left: 0;
      max-width: 100%;
    }
    .games__header__left .game-type-selector > li {
      min-width: 56px;
      width: 56px;
      flex-direction: column;
      gap: 4px;
      height: 56px;
      border-radius: 12px;
    }
    .games__header__left .game-type-selector > li > span {
      position: relative;
      top: 0;
      display: inline;
      background: 0;
      color: white;
      opacity: 0.8;
      font-size: 10px;
      line-height: 12px;
      height: 12px;
      letter-spacing: -2%;
      padding: 0;
    }
    .games__header__left .game-type-selector > li > span::after {
      display: none;
    }
    .games__header__left .game-type-selector > li > svg {
      width: 24px;
      height: 24px;
    }
    .games__header__left .selector {
      width: 100%;
      max-width: 100%;
    }
    .Profile-Block__fields > .game-history-block > section {
      display: none !important;
    }
    .Profile-Block__fields > .game-history-block > div > section > div > div {
      flex-direction: column;
      gap: 12px;
    }
    .Profile-Block__fields > .game-history-block > div > section > div > div > p {
      font-size: 12px;
      line-height: 13px;
    }
    .Profile-Block__fields > .game-history-block > div > section > div > nav {
      width: 50%;
      display: flex !important;
      flex-direction: column;
      gap: 12px;
    }
    .Profile-Block__fields > .game-history-block > div > section > div > nav > p {
      font-size: 12px;
      line-height: 13px;
    }
    .your-trades-history > section {
      display: none !important;
    }
    .your-trades-history > div > div > div {
      flex-direction: column;
      gap: 12px;
    }
    .your-trades-history > div > div > div > p {
      font-size: 12px;
      line-height: 13px;
    }
    .your-trades-history > div > div > nav {
      width: 50%;
      display: flex !important;
      flex-direction: column;
      gap: 12px;
    }
    .your-trades-history > div > div > nav > p {
      font-size: 12px;
      line-height: 13px;
    }
    .payments-block > .deposit-types-block,
  .payments-block > .withdraw-types-block {
      width: 100%;
    }
    .payments-block > .deposit-types-block > section,
  .payments-block > .withdraw-types-block > section {
      display: none;
    }
    .payments-block > .deposit-types-block > div,
  .payments-block .withdraw-types-block > div {
      max-width: 100%;
    }
    .payments-block > .deposit-types-block > div > div,
  .payments-block .withdraw-types-block > div > div {
      padding: 16px !important;
    }
    .payments-block > .deposit-types-block > div > div > div,
  .payments-block .withdraw-types-block > div > div > div {
      display: flex !important;
      flex-direction: column;
      gap: 12px;
      font-size: 12px;
      line-height: 13px;
    }
    .payments-block > .deposit-types-block > div > div > nav,
  .payments-block .withdraw-types-block > div > div > nav {
      display: flex !important;
      flex-direction: column;
      gap: 12px;
      font-size: 12px;
      min-width: 55%;
      line-height: 13px;
    }
    .text-page-container > h1 {
      margin-bottom: 24px;
    }
    .text-page-container > h2 {
      font-size: 16px;
    }
    .text-page-container > p {
      margin-bottom: 12px;
    }
    .main-first-screen .swiper-selector {
      justify-content: center;
    }
    .main-first-screen .swiper {
      height: auto;
    }
    .main-first-screen .swiper > .swiper__wrapper {
      gap: calc(3000px - 100svw + 32px);
    }
    .main-first-screen .swiper > .swiper__wrapper > div {
      flex-direction: column;
      align-items: center;
      max-width: calc(100svw - 32px);
      min-width: calc(100svw - 32px);
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div {
      align-items: center;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div > a {
      margin-left: 0;
      margin-bottom: 15px;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div > h1 {
      margin-bottom: 12px;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div > p {
      text-align: center;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div > ul {
      padding-left: 10px;
      gap: 6px;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div > ul > li {
      font-size: 12px;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > img {
      width: 100%;
      margin-bottom: -20px;
      height: auto;
      transform: none;
    }
    .faq-container > p {
      font-size: 14px;
    }
    .faq-container > nav {
      gap: 12px;
    }
    .faq-container > nav > div {
      gap: 12px;
    }
    .faq-container > nav > div > p,
  .faq-container > nav > div span {
      border-radius: 12px;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 16px;
    }
    .NotFoundBlock img {
      width: 260px;
    }
    .payments-block > div:first-child,
  .bonuses-block > div:first-child {
      flex-direction: column;
      justify-content: flex-start;
      gap: 12px;
      margin-bottom: 24px;
    }
    .payments-block > div:first-child > nav > p,
  .bonuses-block > div:first-child > nav > p {
      font-size: 12px;
      line-height: 16px;
    }
    .sales-list {
      flex-direction: row !important;
      flex-wrap: wrap !important;
    }
    .sales-list > div {
      max-width: 210px;
    }
    .Profile-Block__fields {
      margin-top: 24px;
    }
    .Profile-Block__fields > nav {
      max-width: 100%;
    }
    .Profile-Block__fields .selector {
      max-width: 100%;
    }
    .Profile-Block__fields > .wallet-stat-block {
      flex-direction: column;
      gap: 12px;
    }
    .Profile-Block__fields > .wallet-stat-block > div {
      gap: 8px;
    }
    .games-list > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px 27px;
      max-width: 500px;
      margin-inline: auto;
    }
    .games-list > div > a {
      max-width: 180px !important;
      width: 100%;
    }
    .games-list > div > a > img {
      height: 138px !important;
    }
    .games-list > div > a:hover::after {
      top: 45px !important;
      width: 48px !important;
      height: 48px !important;
      background-size: 100%;
    }
    .games-list > div > a:nth-child(5) {
      max-width: 380px !important;
    }
    .games-list > div > a:nth-child(5) .vpn {
      font-size: 16px !important;
    }
    .games-list > div > a:nth-child(5) > img {
      width: 380px !important;
      height: 280px !important;
    }
    .games-list > div > a:nth-child(5):hover::after {
      height: 64px !important;
      width: 64px !important;
      background-size: 100%;
      top: 108px !important;
    }
    .login-popup,
  .register-popup,
  .deposit-popup,
  .depositPopup {
      display: flex;
      transition: 0.3s;
      transform: translateX(-110vw);
    }
    .login-popup.open,
  .register-popup.open,
  .deposit-popup.open,
  .depositPopup.open {
      transform: translateX(0);
    }
    .depositPopup__form,
  .login-popup__form,
  .register-popup__form {
      max-width: 100%;
      width: 100%;
      height: 100%;
      box-shadow: none !important;
      border-radius: 0 !important;
      border: 0 !important;
      flex-direction: column;
      display: flex;
    }
    .popup--fs > div > div > nav {
      width: 100% !important;
    }
    .popup--fs > div > div > nav .selector__header {
      height: 36px;
    }
    .popup--fs > div > div > nav input[type=text],
  .popup--fs > div > div > nav input[type=password] {
      height: 36px;
    }
    .popup--fs > div > div > nav a.btn {
      margin-top: 4px;
    }
    .header .selector__header {
      height: 36px;
    }
    a.btn,
  a.white-btn {
      padding: 9px 16px;
    }
    .Profile-Block {
      padding-top: 45px;
      flex-direction: column;
    }
    .Profile-Block__container {
      width: 100%;
      max-width: calc(100svw - 32px);
      margin-inline: auto;
    }
    .Profile-Block__fields {
      gap: 12px;
    }
    .Profile-Block__fields .reload-block {
      flex-direction: column;
    }
    .Profile-Block__fields .reload-block a {
      width: 100%;
      max-width: 100%;
    }
    .Profile-Block__fields > a {
      align-self: stretch;
      width: 100%;
      margin-top: 12px;
    }
    .Profile-Block__fields .selector__header {
      height: 36px;
    }
    .Profile-Block__fields .promocode-field > a {
      width: 36px;
      height: 36px;
    }
    .Profile-Block__fields input[type=text],
  .Profile-Block__fields input[type=password],
  .Profile-Block__fields input[type=number] {
      height: 36px;
      width: 100%;
      max-width: 100%;
    }
    .Profile-Block__fields > nav > a.btn {
      width: 100%;
    }
    .Profile-Block__fields > div {
      flex-direction: column;
      max-width: 100%;
      gap: 12px;
    }
    .Profile-Block__fields > div .selector {
      max-width: 100%;
    }
    .Profile-Block__fields > div > a.btn {
      width: 100%;
    }
    .Profile-Block__fields > div > nav {
      width: 100%;
    }
    .Profile-Block__fields > div > nav > a.btn {
      width: 100%;
    }
    .Profile-Block__fields > div > nav .selector__body > div {
      height: 36px;
    }
    .profile-menu-list {
      display: none;
    }
    h1:not(.wheel-spin-block__content > h1, .Profile-Block__container > h1) {
      font-size: 20px !important;
      line-height: 24px !important;
    }
    .main-first-screen {
      padding: 44px 0 50px 0;
    }
    .main-first-screen .swiper > .swiper__wrapper > div > div {
      padding-top: 0;
    }
    .footer-providers {
      min-height: 32px;
      margin-top: 68px;
      overflow-x: auto;
      overflow-y: hidden;
      height: 100px;
      max-width: 100%;
    }
    .main-games-list {
      padding-top: 34px;
    }
    .container {
      margin-inline: auto;
      max-width: calc(100% - 32px);
    }
    .header {
      padding-inline: 16px;
    }
    .header .notifications-toggle {
      display: none;
    }
    .header .user-block > div,
  .header .user-block > svg {
      display: none;
    }
    .header__left__logo {
      width: 70px;
    }
    .header__left__logo > img {
      width: 70px;
      height: 70px;
      transform: translateY(-15px);
    }
    .header__left__burger-toggle {
      display: none;
    }
    .header__right .user-block {
      margin-left: 4px;
    }
    .header__right .user-block > img {
      width: 28px;
      height: 28px;
    }
    .header__right .btn:not(:last-child) {
      display: none;
    }
    .header__right .hml {
      position: absolute;
      visibility: hidden;
    }
    .header__right > .language-toggle {
      display: none;
    }
    .footer {
      padding: 23px 0 80px 0;
      flex-direction: column !important;
      gap: 22px;
    }
    .footer__logo {
      margin-bottom: 8px;
    }
    .footer__right {
      flex-direction: column;
      align-items: center;
    }
    .footer__right > div {
      align-items: center;
      gap: 12px;
    }
    .footer__left {
      align-items: center;
    }
    .footer__left > span {
      text-align: center;
      margin-bottom: 14px;
    }
    .footer .age-block {
      width: 71px;
      height: 71px;
    }
    .footer .age-block > p {
      font-size: 28px;
      font-weight: 500;
      line-height: 28px;
    }
    .MobileMenu,
  .Profile-MobileMenu,
  .SearchMenu {
      display: flex !important;
    }
    .MobileSupport {
      right: 12px;
      bottom: 60px;
      width: 36px;
      height: 36px;
    }
    .MobileSupport > svg {
      width: 20px;
      height: 20px;
    }
    .NotFoundBlock > .container > h1 {
      font-size: 48px !important;
      line-height: 54px !important;
    }
    .Wallet-History > div > div {
      padding: 16px !important;
    }
    .Wallet-History > div > div > nav {
      display: flex !important;
      flex-direction: column;
      gap: 12px;
      font-size: 12px;
      line-height: 13px;
      width: 50%;
    }
    .Wallet-History > div > div > div {
      flex-direction: column;
      gap: 12px;
    }
    .Wallet-History > div > div > div > p {
      font-size: 12px;
      line-height: 13px;
    }
    .Wallet-History > section {
      display: none !important;
    }
    .wager--form {
      background: transparent;
    }
  }
  @media (max-width: 489px) {
    .sales-list {
      width: 100%;
      flex-direction: column !important;
      gap: 12px !important;
    }
    .sales-list > div {
      padding-bottom: 15px !important;
      height: auto !important;
      width: 100% !important;
      max-width: 99999px !important;
    }
    .sales-list > div > img {
      width: 126px !important;
      height: 126px !important;
    }
    .sales-list > div > h5 {
      font-size: 48px !important;
      margin-bottom: 4px !important;
    }
    .sales-list > div > p {
      font-size: 16px !important;
    }
  }
  @media (min-width: 1280px) {
    .animation-Loading > img {
      height: 100%;
    }
  }
  @media (max-width: 465px) {
    .games-list > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px 27px;
    }
    .games-list > div > a {
      max-width: 121px !important;
      width: 100%;
    }
    .games-list > div > a > img {
      height: 93px !important;
    }
    .games-list > div > a:hover::after {
      top: 28px !important;
      width: 36px !important;
      height: 36px !important;
      background-size: 100%;
    }
    .games-list > div > a:nth-child(5) {
      max-width: 269px !important;
    }
    .games-list > div > a:nth-child(5) > img {
      width: 267px !important;
      height: 205px !important;
    }
    .games-list > div > a:nth-child(5):hover::after {
      height: 64px !important;
      width: 64px !important;
      background-size: 100%;
      top: 70px !important;
    }
  }
  @media screen and (max-width: 960px) {
    .container-ref-img {
      display: none;
    }
  }
  .container-ref-img {
    max-width: 700px;
    width: calc(100svw - 32px);
    position: relative;
  }
  
  .container-ref-img > img {
    width: 100%;
    border-radius: 10%;
    height: 100%;
  }
  
  .MobileMenu {
    display: none;
    position: fixed;
    bottom: -1px;
    left: 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    width: 100svw;
    background: #221452;
    padding: 11px 20px 9px 20px;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1000;
  }
  
  .MobileMenu > a {
    display: flex;
    gap: 5px;
    padding-bottom: 6px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .MobileMenu > a > *:not(a) {
    pointer-events: none;
  }
  
  .MobileMenu > a:not(.MobileMenu > a:nth-child(3)) {
    padding: 6px 10px;
  }
  
  .MobileMenu > a:not(.MobileMenu > a:nth-child(3)).open {
    border-radius: 4px;
    background: #422397;
  }
  
  .MobileMenu > a > svg {
    width: 14px;
    height: 14px;
  }
  
  .MobileMenu > a > svg > path,
  .MobileMenu > a > svg > g > path {
    stroke: #6b41e9;
  }
  
  .MobileMenu > a > p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }
  
  .MobileMenu > a > a.btn {
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    position: absolute;
    top: 0;
    margin-top: -50px;
    background: linear-gradient(85.32deg, #8a2be9 3.78%, #32a5e5 48.83%, #3dffdc 96.22%);
    height: 40px;
  }
  
  .SearchMenu {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
    background: var(--dark);
    transform: translateY(100dvh);
    height: calc(100% - 40px);
    max-height: 9999px;
    transition: 0.3s;
    width: 100%;
    position: fixed;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    box-sizing: border-box;
    padding: 8px 16px;
    padding-bottom: 70px;
  }
  
  .SearchMenu section {
    width: 100%;
    margin-bottom: 24px;
  }
  
  .SearchMenu section > div {
    width: 100%;
  }
  
  .SearchMenu section > nav.result {
    position: absolute;
    max-width: calc(100% - 32px);
    margin-top: 12px;
    transition: 0.1s;
    flex-direction: column;
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    border-radius: 10px;
    border: 2px solid var(--purpur);
    z-index: 1050;
    background: var(--dark);
    padding: 12px 0;
  }
  
  .SearchMenu section > nav.result > a {
    display: flex;
    padding-right: 6px;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    height: 36px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
  }
  
  .SearchMenu section > nav.result > a * {
    pointer-events: none;
  }
  
  .SearchMenu section > nav.result > a:hover {
    background: #291561;
  }
  
  .SearchMenu section > nav.result > a:hover > p {
    color: white;
  }
  
  .SearchMenu section > nav.result > a > p {
    align-items: center;
    display: flex;
    gap: 6px;
    font-size: 15px;
    color: var(--purpur);
  }
  
  .SearchMenu section > div > input {
    max-width: 100% !important;
    width: 100%;
  }
  
  .SearchMenu > nav {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 100%;
    padding-left: 16px;
  }
  
  .SearchMenu > nav > a {
    display: flex;
    gap: 6px;
  }
  
  .SearchMenu > nav > a > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .SearchMenu > nav > a > svg {
    width: 16px;
  }
  
  .SearchMenu > nav > a > p {
    font-size: 12px;
    line-height: 14px;
  }
  
  .SearchMenu .selector {
    margin-top: 32px;
    width: 100%;
    max-width: 100%;
  }
  
  .SearchMenu.open {
    transform: translateY(0);
  }
  
  .SearchMenu > .down-button {
    width: 36px;
    margin-bottom: 40px;
    min-height: 36px;
    background: #5033aa;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .SearchMenu > .down-button:hover {
    transform: scale(0.95);
  }
  
  .Profile-MobileMenu {
    position: fixed;
    overflow-y: auto;
    padding-bottom: 70px;
    display: none;
    background: var(--dark);
    transform: translateY(100dvh);
    height: calc(100% - 40px);
    transition: 0.3s;
    padding-bottom: 70px;
    width: 100%;
    position: fixed;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    box-sizing: border-box;
    padding: 8px 16px;
  }
  
  .Profile-MobileMenu .selector {
    margin-top: 32px;
    width: 100%;
    max-width: 100%;
  }
  
  .Profile-MobileMenu .profile-menu-list {
    display: flex;
    width: 100%;
    margin-left: 0;
  }
  
  .Profile-MobileMenu .profile-menu-list a svg,
  .Profile-MobileMenu .profile-menu-list a p {
    pointer-events: none;
  }
  
  .Profile-MobileMenu.open {
    transform: translateY(0);
  }
  
  .Profile-MobileMenu > .down-button {
    width: 36px;
    margin-bottom: 40px;
    min-height: 36px;
    background: #5033aa;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .Profile-MobileMenu > .down-button:hover {
    transform: scale(0.95);
  }
  
  .telegram-log {
    background: #2aabee;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px 14px;
    justify-content: center;
  }
  
  .telegram-log > svg {
    margin-inline: 4px;
  }
  
  .game-frame {
    border: 0;
    position: relative;
    z-index: 10000;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .game-frame__wrapper {
    background: black;
    position: fixed;
    z-index: 10000;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  /*# sourceMappingURL=globals.css.map */
  .is-notif {
    position: absolute;
    top: 0;
    right: 6px;
  }
  
  body:has(.full-screen) .depositPopup__form {
    margin-top: 7svh;
  }
  
  body:has(.full-screen) .Mobile-ProfileRight {
    padding-top: 9svh;
  }
  
  body:has(.full-screen) .Mobile-ProfileRight svg {
    top: 100px;
  }
  
  body:has(.full-screen) .Profile-MobileMenu {
    margin-top: 9svh;
  }
  
  body:has(.full-screen) .wrap__mwjDj {
    margin-bottom: 9svh;
  }
  
  body:has(.full-screen) header {
    margin-top: 9svh;
  }
  
  body:has(.full-screen) .MobileMenu {
    padding-bottom: 7svh;
  }
  
  body:has(.full-screen) .jv-mobile-or-tablet [class^=mobileContainer] {
    margin-top: 11svh !important;
    margin-bottom: 5svh !important;
  }
  
  body:has(.full-screen) .Toastify__toast-container {
    margin-top: 11svh !important;
  }
  
  .games-list__item .favorite-game {
    /* position: relative; */
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .games-list__item .favorite-game.active path {
    fill: red;
    fill-opacity: 1;
  }
  
  body:has(.alert-tg.active) jdiv {
    opacity: 0 !important;
  }
  
  .alert-tg {
    position: fixed;
    inset: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease;
  }
  .alert-tg.active {
    opacity: 1;
    pointer-events: all;
  }
  .alert-tg.active .content {
    transform: translateY(0);
  }
  .alert-tg .content {
    position: absolute;
    inset-inline: 0;
    z-index: auto;
    bottom: 0;
    min-height: 300px;
    background: #17181a;
    border-radius: 20px 20px 0px 0px;
    padding-inline: 36px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateY(100%);
    transition: transform 0.8s ease;
  }
  .alert-tg .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px;
  }
  .alert-tg button {
    width: 100%;
    height: 65px;
    background: #438af7;
    border-radius: 32px;
    border: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
  }
  .alert-tg p {
    opacity: 0.5;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .alert-tg .t {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .alert-tg .t span {
    color: #448bf5;
  }
  
  body:has(.full-screen) .support-box {
    bottom: 14svh;
  }
  
  .game-open .support-box {
    display: none;
  }
  
  .support-box {
    z-index: 99999;
    position: fixed;
    bottom: 6svh;
    right: 30px;
    background: linear-gradient(95deg, rgb(47, 4, 83) 20%, rgb(47, 4, 83) 80%);
    border-radius: 99%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .support-box svg {
    width: 40px;
    height: 40px;
    padding: 10px;
    fill: white;
  }
  
  .referal-button {
    background: var(--blueGradient);
    padding: 12px 8px;
    border: none;
    font-family: "Inter";
    color: white;
    font-weight: 400;
    border-radius: 10px;
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 960px) {
    .referal-button {
      font-size: 8px;
      width: 74px;
    }
  }
  .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 30px 0;
  }
  
  .det {
    margin-bottom: 30px;
  }
  
  .date-filters {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    max-width: 90%;
  }
  
  .date-filters input {
    border: 1px solid white;
    border-radius: 24px;
    padding: 10px;
    outline: none;
    background: transparent;
    font-size: 12px;
    color: white;
    fill: white;
  }
  
  .deposit_footer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 4vh !important;
  }
  
  .deposit_footer button, .deposit_footer h1 {
    color: white;
  }
  
  .deposit_footer #activate {
    font-size: 12px !important;
  }
  
  .deposit_footer .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  
  .deposit_footer h1 {
    text-align: center;
  }
  
  .deposit_footer button {
    outline: none;
    border: none;
  }
  
  .activatelink {
    background: var(--blueGradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }