/*
FONTS START
*/

:root {
  height: 100%;
  --toastify-toast-offset: 24px !important;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-ExtraBold.ttf");
  font-weight: 800;
}
/*
FONTS END
*/
html,
body {
  overflow: hidden;
}
body {
  touch-action: none !important;
}
html {
  --dark: #120a29;
  --purple: #2e0453;
  //--purple:
  --white: #fff;
  --bronze: #60390b;
  --silver: #c2c2c2;
  --diamond: #cc23f9;
  --gold: #ff8a00;
  --purpur: #7762cd;
  --blueGradient: linear-gradient(73.15deg, #622ec6 11.62%, #5e67ee 50%, #71ced0 88.38%);
  --btnStroke: linear-gradient(90deg, #7762cd 0%, #53cbff 55.5%, #7762cd 100%);

  --blue: #1c3ac3;
  --darkPurpur: #36217d;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
li,
input,
div {
  font-family: "Inter";
  color: white;
  font-weight: 400;
}
.Toastify__toast-theme--dark {
  background: var(--purple) !important;
}
.op80 {
  opacity: 0.8;
}
img {
  user-select: none;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
a {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}
h1 {
  font-size: 36px;
}
* {
  padding: 0;
  margin: 0;
}
body {
  position: relative;
  display: flex;
  max-height: 100%;

  overflow: hidden;
  &.game-open > jdiv {
    display: none;
  }
}
#root {
  display: flex;
  width: 100%;
}
.container-main {
  display: flex;
  position: relative;
  scroll-behavior: smooth;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  width: 100%;
  max-width: 100svw;
  background: var(--dark);
  overflow-x: hidden;
}

a.white-btn {
  position: relative;
  &:active {
    transform: scale(0.96);
  }
  font-size: 14px;
  padding: 12px 16px;
  background: transparent;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  border: 1.3px solid var(--white);
  transition: 0.2s box-shadow, 0.3s transform;
  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 1);
  }
}

a.btn {
  &.check-btn {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 24px;
      height: 24px;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  position: relative;
  font-size: 14px;
  text-align: center;
  padding: 12px 16px;
  border-radius: 10px;
  background: var(--blueGradient);
  box-sizing: border-box;

  transition: 0.2s box-shadow, 0.3s filter, 0.3s background;

  &:hover {
    background: linear-gradient(#6e37dd, #6e37dd);
  }
}
.logo {
  width: 100px;
  height: 45px;
  > img {
    width: 100px;
    background-size: cover;
    height: 100px;
    transform: translateY(-28px);
  }
}
.media-link {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.language-toggle {
  margin-right: 12px;
  position: relative;
  &__header {
    display: flex;
    cursor: pointer;
    align-items: center;
    p {
      width: 28px;
      font-size: 18px;
      user-select: none;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: calc(100% + 6px);
    background: var(--darkPurpur);
    border-radius: 12px;

    box-sizing: border-box;
    padding: 12px 0;
    &__item {
      padding-inline: 16px;
      > p {
        user-select: none;
      }
      > svg {
      }
      display: flex;
      white-space: nowrap;

      align-items: center;
      height: 26px;
      gap: 6px;
      &:hover {
        background: #5033aa;
      }
    }
  }
}
.header {
  display: flex;
  max-width: 100%;

  position: relative;
  padding-right: 44px;

  min-height: 68px;
  padding-left: 60px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;

  border-image: var(--blueGradient) 30;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  &__right {
    display: flex;
    margin-left: 28px;
    position: relative;
    &__list {
      position: absolute;
      top: 100%;
      border: double 1px transparent;

      background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
      background-origin: border-box;
      background-clip: padding-box, border-box;
      border-radius: 12px;
      right: 0;
      z-index: 100;
      box-sizing: border-box;
      padding: 6px 0;
      overflow: hidden;
      &__item {
        padding-inline: 16px;
        > p {
          font-size: 14px;
          line-height: 14px;
        }
        > svg {
          height: 16px;
          width: 16px;
        }
        display: flex;
        white-space: nowrap;

        align-items: center;
        height: 26px;
        gap: 6px;
        &:hover {
          background: var(--blueGradient);
        }
      }
    }
    justify-content: right;
    align-items: center;
    > .user-balance {
    }
    > .notifications-toggle {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      position: relative;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background: var(--blueGradient);
      transition: 0.1s opacity;
      border-radius: 50%;
      > svg {
        width: 16px;
        height: 16px;
      }
    }
    > .white-btn {
      margin-right: 12px;
    }
    &__media-link {
      margin-right: 14px;
    }
  }
  &__left {
    position: relative;
    &__list {
      position: absolute;
      top: 100%;
      border: double 1px transparent;
      border-radius: 10px;
      background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
      background-origin: border-box;
      background-clip: padding-box, border-box;
      border-radius: 12px;
      left: -9px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 6px 0;
      &__item {
        padding-inline: 16px;
        &:hover {
          background: var(--blueGradient);
        }
        > p {
          font-size: 12px;
          line-height: 14px;
        }
        > svg {
        }
        display: flex;
        white-space: nowrap;

        align-items: center;
        height: 26px;
        gap: 6px;
      }
    }
    display: flex;
    justify-content: left;
    align-items: center;
    &__burger-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      z-index: 100;
      margin-right: 28px;
      height: 24px;
    }
  }
}
.ref-fields {
  > div > nav {
    width: 100% !important;
    max-width: 600px;
    .copy-input > input {
      max-width: 100%;
      width: 100%;
    }
  }
}
.footer {
  display: flex;

  padding: 50px 125px 30px 125px;
  background: var(--dark);
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  &__right {
    display: flex;
    justify-content: right;
    align-items: flex-start;
    gap: 60px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      > h6,
      a {
        font-size: 14px;
        line-height: 20px;
      }

      > h6 {
        color: #7762cd;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    .age-block {
      display: flex;
      align-items: center;
      width: 42px;
      user-select: none;
      height: 42px;
      opacity: 0.2;
      justify-content: center;
      p {
        font-size: 20px;
        font-family: "Montserrat";
      }
      border-radius: 50%;
      box-sizing: border-box;
      color: white;
      border: 2px solid white;
    }
    > span {
      opacity: 0.7;
      font-size: 12px;
      max-width: 318px;
      margin-bottom: 16px;
    }
    > section {
      display: flex;
      gap: 12px;
      margin-bottom: 8px;
      align-items: center;
      > p {
        font-weight: 700;
        font-family: "Montserrat";
        font-size: 14px;
      }
    }
  }
  &__logo {
    margin-bottom: 3px;
    width: 81px;
    height: 49px;
  }
}
.footer-providers {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #2a3261;
  gap: 45px;
  margin-top: 132px;
  align-items: center;
  padding: 40px 0;
  > div {
    display: flex;
    flex-wrap: nowrap;
    gap: 45px;
    > img {
      height: 30px;
      transition: 0.2s;

      pointer-events: all;
    }
  }
}
.container {
  display: flex;
  width: 100%;
  max-width: 960px;
  margin-inline: auto;
}

.main-first-screen {
  padding: 50px;
  .container {
    flex-direction: column;
  }
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(20, 2, 49, 0) 0%, rgba(70, 44, 150, 0.8) 70.29%, rgba(20, 2, 49, 0.76) 100%);
  .swiper-selector {
    display: flex;
    gap: 7px;
    > div {
      transition: 0.2s;
      cursor: pointer;
      width: 17px;
      height: 4px;
      border-radius: 3px;
      background: #532cc6;

      &.selected {
        width: 34px;
        background: #7762cd;
      }
    }
  }
  .swiper {
    display: flex;

    max-width: 100%;
    position: relative;
    height: 348px;

    > .swiper__wrapper {
      width: 999999px;
      display: flex;
      transition: 0.5s;
      gap: 2040px;
      > div {
        user-select: none;
        pointer-events: none;
        position: relative;
        max-width: 100%;
        min-width: 960px;
        display: flex;

        justify-content: space-between;
        > div {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          padding-top: 50px;
          > h1 {
            font-weight: 800;
            font-size: 32px;
            > span {
              font-weight: 800;
              background: var(--blueGradient);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            user-select: none;
            margin-bottom: 22px;
          }
          > p {
            margin-bottom: 16px;
          }
          > ul {
            user-select: none;
            list-style-type: none;

            display: flex;

            gap: 10px;
            flex-direction: column;

            > li {
              font-size: 14px;
            }
          }
          > a {
            display: inline-flex;
            margin-top: 24px;

            pointer-events: all;
          }
        }
        > img {
          height: 280px;
          transform: scale(1.4);
          margin-top: 20px;
        }
      }
    }
  }
}
.main-games-list {
  padding-top: 57px;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    > a {
      align-self: center;
      width: 100%;
      margin-top: 24px;
    }
  }
}
.games__header {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  width: 100%;

  &__left {
    display: flex;
    gap: 40px;
    align-items: flex-end;
    justify-content: left;
    .selector {
      width: 250px;
    }
  }
  justify-content: space-between;
}
.animation-Loading {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  > img {
    object-fit: cover;
  }
  position: relative;
}
.register-popup__form {
  label.password-input {
    margin-bottom: 12px !important;
  }
}
.disable-scroll {
  height: calc(var(--window-inner-height) - 1px);
  overflow: hidden;
}

.selector {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 250px;
  &.colorized {
    .selector__header {
      background: var(--blueGradient);
      border: 0;
      > svg > path {
        stroke: white;
      }
      > p {
        color: white;
      }
    }
  }
  &.w-selector {
    .selector__header {
      padding-right: 45px;
      padding-left: 40px;
      > p {
        color: white;
      }
    }
    .selector__body {
      max-width: calc(100svw - 32px);
      width: 824px;
      max-height: 600px;
      overflow-y: auto;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > div {
        width: 205px;
      }
      box-sizing: border-box;
    }
  }
  &__header > svg {
    transition: 0.2s;
  }
  &.open > .selector__header > svg {
    transform: rotate(180deg);
  }
  &.open > .selector__body {
    pointer-events: all;
    opacity: 1;
  }
  &:not(.white-selector).open > .selector__header {
  }

  &__body {
    pointer-events: none;
    opacity: 0;
    transition: 0.1s;
    flex-direction: column;
    box-sizing: border-box;
    flex-wrap: wrap;
    position: absolute;
    top: calc(100% + 12px);
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 100%;
    max-width: 100%;
    z-index: 50;

    padding: 12px 0;
    > div {
      padding-right: 6px;
      padding-left: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      height: 36px;
      cursor: pointer;
      > a {
        display: flex;
        align-items: center;

        width: 100%;
        height: 100%;
      }
      *:not(a) {
        pointer-events: none;
      }
      user-select: none;
      &:hover {
        background: #291561;
        > p {
          color: white;
        }
      }
      width: 100%;
      display: flex;
      gap: 6px;
      align-items: center;
      > p {
        align-items: center;
        display: flex;
        gap: 6px;
        font-size: 15px;
        color: var(--purpur);
      }
    }
  }
  &__header {
    &:not(.white-selector > .selector__header):hover {
    }
    > * {
      user-select: none;
      pointer-events: none;
    }
    display: flex;
    position: relative;
    min-width: 100%;
    max-width: 100%;
    height: 42px;
    align-items: center;
    box-sizing: border-box;
    padding-inline: 8px;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    > p {
      align-items: center;
      display: flex;
      gap: 6px;
      font-size: 15px;
      color: var(--purpur);
    }
  }
}
.user-block {
  margin-left: 8px;
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: nowrap;
  > img {
    width: 38px;
    height: 38px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > p {
      font-size: 14px;
      line-height: 14px;
    }
    > span {
      opacity: 0.5;
      font-size: 10px;
      line-height: 14px;
    }
  }
  > svg {
    width: 17px;
    height: 17px;
  }
}

.white-selector {
  margin-right: 8px;

  .selector__header {
    padding-inline: 8px;
    box-sizing: border-box;
    gap: 10px;
    min-width: 105px;
    > p {
      color: white;
    }
    opacity: 1;
    transition: 0.2s box-shadow;
  }
  &.open > .selector__header {
  }
  .selector__body {
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
}
.game-type-selector {
  margin-left: 12px;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  position: relative;
  list-style-type: none;

  > li {
    display: flex;
    align-items: center;
    min-width: 40px;
    justify-content: center;
    border-radius: 12px;
    transition: 0.3s;
    height: 40px;
    background: #8f47fe;
    cursor: pointer;
    > svg {
      width: 18px;
    }
    > * {
      pointer-events: none;
    }

    position: relative;
    &:hover > span {
      display: flex;
    }
    &.active {
      min-width: 80px !important;
      background: var(--blueGradient);
    }
    &:hover,
    &.active {
      background-color: #8f47fe;
    }
    > span {
      display: none;
      position: absolute;
      &:after {
        top: 100%;
        display: inline-block;
        border: 9px solid transparent;
        border-top: 7px solid #8f47fe;
        content: "";
        position: absolute;
      }

      top: -44px;
      background: #8f47fe;
      border-radius: 6px;
      display: none;
      justify-content: center;
      transition: 0.3s;
      padding: 7px 6px;
      text-align: center;
    }
  }
}
input[type="text"],
input[type="password"],
input[type="number"] {
  &.error {
    border-color: #ac2929;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  position: relative;
  color: white;
  opacity: 0.8;
  max-width: 205px;

  font-family: "Montserrat";
  font-weight: 400;
  border: double 1px transparent;
  border-radius: 10px;
  background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  box-sizing: border-box;

  outline: none;
  height: 42px;

  font-size: 15px;
  line-height: 15px;
  &::placeholder {
    color: white;
    opacity: 1;
    font-size: 15px;
    font-weight: 400;
  }
  padding-inline: 10px;
}
.games__header__right > .search-input {
  > input::placeholder {
    padding-left: 25px;
  }
  > input::-moz-placeholder,
  > input:-moz-placeholder {
    padding-left: 25px;
  }
  > input:-ms-input-placeholder {
    padding-left: 25px;
  }
  &::after {
    right: 48px;
  }
}
.search-input {
  position: relative;
  &::after {
    position: absolute;
    width: 16px;
    height: 16px;

    right: 10px;
    top: calc(50% - 8px);
    content: url("./../../public/images/search.svg");
  }

  > input {
    max-width: 250px !important;
  }
}
.wheel-spin-block {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  > div.coverage {
    top: 0;
    left: 0;
    position: absolute;
    width: 140%;
    height: 100%;
    left: -20%;

    > nav {
      background: #00000098;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    > img.bg {
      width: 100%;
      height: 100%;
      filter: grayscale(1);
      position: absolute;

      object-fit: cover;
    }
  }
  &__content {
    padding-top: 50px;
    position: relative;

    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    > h1 {
      font-family: "Inter";
      text-transform: uppercase;
      font-size: 79px;
      max-width: 654px;
      font-weight: 700;
      line-height: 80px;
      background: -webkit-linear-gradient(270deg, #ffffff 0%, #ffffff 60%, rgba(255, 255, 255, 0) 100%);

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      color: white;
    }
    .wheel-block {
      min-height: calc(100svh - 308px);
      width: 100vw;
      height: 800px;

      .zeus {
        position: absolute;
        left: calc(50% - 152px - 385px);
        transform: scale(-1, 1);
        top: 54px;
        width: 385px;
        z-index: 11;
      }
      .panda {
        position: absolute;
        width: 400px;
        top: -50px;
        left: calc(50% + 150px);
      }
      .coins1 {
        position: absolute;
        bottom: 0;
        width: 502px;
        right: calc(50% + 430px);
      }
      .coins2 {
        position: absolute;
        top: -322px;

        width: 502px;
        left: calc(50% + 345px);
      }
      .dogs {
        z-index: 12;
        width: 558px;
        position: absolute;
        top: 418px;
        left: calc(50% + 130px);
      }
      position: relative;
      text-align: center;
      &__spin {
        background: #5033aa;
        width: 233px;
        z-index: 10;
        position: absolute;
        top: 240px;
        left: calc(50% - 117px);
        border-radius: 50%;
        box-shadow: 0px 0px 21.9px 0px #774df9;

        box-shadow: 0px 0px 21.9px 0px #774df9;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 233px;
        > button {
          position: relative;
          outline: none;
          margin-top: 0;
          width: 210px;
          height: 210px;
          color: white;
          font-family: "Inter";
          z-index: 10;
          font-size: 32px;
          cursor: pointer;
          font-weight: 800;
          box-shadow: 0px 0px 26.8px 0px #774df9;
          background: #774df9;
          background: radial-gradient(50% 50% at 50% 50%, #774df9 0%, #6340d0 70%, #7851f3 85.5%, #9775ff 100%);
        }
      }
      &__wheel {
        > svg {
          transition: 5s;
        }
        position: absolute;
        left: calc(50% - 54px);
        max-height: 900px;
        overflow: hidden;
        > svg {
          width: 1000px;
          height: 1000px;
        }
        top: 0;
        z-index: 10;
        position: absolute;
        align-self: center;
        margin-top: -148px;
        margin-left: -452px;
      }
      &__thumbler {
        position: relative;
        z-index: 12;
      }
      margin-top: 12px;
    }
    button {
      background: #754bf7;
      font-size: 33px;
      padding: 10px;
      line-height: 40px;
      color: white;
      border-radius: 150px;
      border: 0;
      outline: none;
      font-family: "Inter";
      text-transform: uppercase;
      align-self: center;
      font-weight: 700;
      margin-top: 16px;
    }
  }

  .wheel-logo {
    position: absolute;
    top: 58px;
    width: 180px;
    height: 100px;
    > img {
      width: 100%;
      transform: scale(1.4);
      height: 100%;
    }
    left: 186px;
  }
}
.games-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div {
    display: grid;
    grid-gap: 12px 24px;
    max-width: 100%;

    grid-template-columns: repeat(6, 140px);
    &:nth-child(odd) > a:nth-child(9) {
      grid-column: 5 / 7;
    }
    &:nth-child(even) > a:first-child {
      grid-column: 1 / 3;
    }
    &:nth-child(odd) > a:nth-child(9),
    &:nth-child(even) > a:first-child {
      grid-row: 1 / 3; /* 5 */

      max-width: 304px;
      > img {
        height: 263px;
        margin-bottom: 24px;
      }
      &:hover::after {
        top: 83px;
        width: 64px;
        height: 64px;

        background-image: url("../../public/images/play.svg");
        background-size: contain;
      }
    }
    > a {
      position: relative;
      max-width: 140px;
      width: 100%;
      background: #1e143e;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      &:hover::after {
        position: absolute;
        top: 25px;

        width: 38px;
        height: 38px;
        content: "";
        background-image: url("../../public/images/play.svg");
      }
      &:hover > img {
        opacity: 0.5;
      }
      > img {
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
        height: 107px;
        object-fit: cover;
        margin-bottom: 19px;
      }
      > h4 {
        padding-inline: 10px;
        text-overflow: ellipsis;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        box-sizing: border-box;

        align-self: flex-start;
      }
      > span {
        padding-inline: 10px;
        opacity: 0.4;
        font-size: 10px;
        align-self: flex-start;
        line-height: 18px;
        margin-bottom: 6px;
      }
    }
  }
}

.text-page-container {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 87px;
  > h1 {
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  > h2 {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  > p {
    opacity: 0.8;
    > ul {
      padding-left: 25px;
    }
    > a {
      font-weight: 500;
      color: #2b84e9;
      text-decoration: underline;
    }
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 24px;
  }
  > h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  > img {
    width: 261px;
    margin-bottom: 24px;
  }
}
.faq-container {
  > h1 {
    margin-bottom: 13px;
  }
  > p {
    line-height: 1.2;
    max-width: 600px;
    font-size: 24px;
  }
  > nav {
    display: flex;
    gap: 32px;
    flex-direction: column;
    width: 100%;
    > div {
      display: flex;
      gap: 32px;
      flex-direction: column;
      width: 100%;
      > span {
        align-self: flex-start;
        border-radius: 30px;
        background: transparent;

        box-sizing: border-box;
        padding: 16px 32px;
        cursor: pointer;
        user-select: none;
        font-size: 20px;
        line-height: 24px;
        border: double 1px transparent;
        border-radius: 12px;
        background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
      &.open > p {
        display: flex;
      }
      &.ride > p {
        max-height: 9999px;
        transform: translateX(0);
      }
      > p {
        font-size: 20px;
        line-height: 24px;
        pointer-events: none;
        padding: 16px 32px;
        max-width: 762px;
        overflow: hidden;
        align-self: flex-end;
        border-radius: 12px;
        background: var(--blueGradient);
        box-sizing: border-box;
        max-height: 0;
        display: none;
        transform: translateX(100%);
        transition: 0.2s;
      }
    }
  }
}
.Mobile-ProfileRight {
  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
  }
  display: none;
  width: 100svw;
  transform: translateX(120vw);
  transition: 0.3s transform;
  &.open {
    transform: translateX(0);
  }
  z-index: 1200;
  background: var(--dark);
  height: 100dvh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
}
.Mobile-Selector {
  display: none;
  margin-bottom: 12px;
  gap: 8px;
  max-width: 100%;
  overflow-x: auto;
  > a {
    padding: 8px 12px;
    color: white;
    opacity: 0.8;
    &.selected {
      background: var(--purple);
    }
    font-size: 12px;
    border-radius: 10px;
  }
}
.NotFoundBlock {
  .container {
    flex-direction: column;
    align-items: center;
    padding-top: 28px;
    h1 {
      font-size: 80px;
    }
    h5 {
      font-size: 18px;
    }
    a {
      margin-top: 24px;
    }
  }
}
.f50 {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  position: relative;
}
.card-in {
  border: double 1px transparent;
  background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
  background-origin: border-box;
  box-sizing: border-box;
  background-clip: padding-box, border-box;
  border-radius: 12px;
  width: 100%;
  position: relative;
  max-width: 320px;
  > img {
    height: 24px;
    margin-bottom: 42px;
  }
  > span {
    font-size: 22px;
  }
  p {
    color: white;
  }
  padding: 20px;
}
.stroke-b-img {
  width: 48%;
  border: double 1px transparent;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
  background-origin: border-box;
  box-sizing: border-box;
  background-clip: padding-box, border-box;
  min-height: 36px;
  padding: 8px 12px;
  > img {
    height: 24px;
    width: fit-content;
  }
}
.copy-input {
  margin-bottom: 8px;
  position: relative;
  > input {
    box-sizing: border-box;
    padding-right: 48px;
  }
  display: flex;
  align-items: center;
  > svg {
    position: absolute;
    cursor: pointer;
    right: 16px;
    width: 16px;

    height: 16px;
    > path {
      pointer-events: none;
      fill: white;
    }
  }
}
.finish-registration {
  background: #221452;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 12px 24px;
  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    align-items: center;
    margin: 0 auto;
    gap: 10px;
    div {
      display: flex;
      align-items: center;
      gap: 8px;
      opacity: 0.9;
      p {
        font-size: 16px;
      }
    }
    a {
      font-size: 14px;
      color: #95c8ff;
    }
  }
}
.Profile-Block {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: 65px;
  gap: 40px;
  .selector__body {
    max-height: 500px;
    overflow-y: auto;
  }
  &.centered {
    max-width: 960px;
    margin-inline: auto;
  }
  &__container {
    max-width: 960px;
    display: flex;
    flex-direction: column;
    > p {
      opacity: 0.8;
      font-size: 12px;
      line-height: 15px;
      max-width: 100%;
    }
    > span {
      display: flex;
      gap: 5px;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 6px;

      > p {
        opacity: 0.3;
        font-size: 12px;
        user-select: none;
      }
    }
  }
  &__fields {
    max-width: 960px;

    .deposit-bonus-select {
      > div {
        width: 48px;
        height: 48px;
        border: double 2px transparent;
        border-radius: 8px;
        background-image: linear-gradient(var(--dark), var(--dark)), var(--blueGradient);
        background-origin: border-box;
        box-sizing: border-box;
        background-clip: padding-box, border-box;

        &:last-child {
          background: transparent;
          border: 1px solid #df7373;
          > svg > path {
            stroke: #df7373;
          }
        }
        &.selected {
          border: 0;
          > svg > path {
            stroke: white;
          }
          background: var(--blueGradient);
        }
      }
    }
    .sales-list {
      gap: 12px !important;
      > div {
        width: 100% !important;
        > p {
          font-size: 16px;
          max-width: 200px;
        }
        > h5 {
          margin-top: -9px;
          margin-bottom: 4px;
        }
        > img {
          width: 126px !important;
          height: 126px !important;
        }
        height: auto !important;
        padding-bottom: 16px !important;
      }
    }
    .longtext {
      opacity: 0.8;
      font-size: 14px;
      line-height: 18px;
    }
    > h2 {
      font-size: 22px;
    }
    > .user-vip-levels {
      margin: 0 0 12px 0;
      max-width: 100%;
      overflow-x: hidden;
      align-items: center;
      display: flex;
      &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: #bcb6eb;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #b8c0c2;
      }

      &::-webkit-scrollbar-track:active {
        background-color: #b8c0c2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--diamond);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: var(--diamond);
      }

      &::-webkit-scrollbar-thumb:active {
        background-color: var(--diamond);
      }
      flex-direction: column;
      gap: 13px;
      overflow-x: auto;
      > section {
        max-width: 198px;
        > input {
          width: 198px;
          top: 0;
          -webkit-appearance: none;
          vertical-align: middle;
          outline: none;
          border: none;
          padding: 0;
          background: none;
          &::-webkit-slider-runnable-track {
            background-color: rgba(196, 196, 196, 0.5);
            height: 8px;
            border-radius: 6px;
            border: 1px solid transparent;
          }
          &::-webkit-slider-thumb {
            width: 30px;
            height: 8px;
            border-radius: 6px;
            appearance: none;
            cursor: pointer;
            position: relative;
            top: -1px;
            z-index: 10;
            background: #5033aa;
          }
        }
      }
      > div {
        align-self: flex-start;
        margin: 12px 0;
        transition: 0.1s;
        display: flex;

        position: relative;
        gap: 20px;
        max-width: 999999px;
        width: fit-content;
        > div {
          max-width: 224px;
          width: 100%;
          min-width: 224px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #5033aa;
          border-radius: 10px;
          > img {
            margin-top: -9px;
            margin-bottom: 4px;
            width: 48px;
            height: 48px;
          }
          > h4 {
            margin-bottom: 12px;
            font-size: 22px;
            line-height: 1;
            font-weight: 500;
          }

          > nav {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            > p {
              font-size: 12px;
              line-height: 14px;
              opacity: 0.6;
            }
            padding: 4px 0;
            > span {
              font-size: 14px;
              line-height: 1;
              font-weight: 500;
            }
            &:last-child {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }
            &:nth-child(odd) {
              background: #6d36dd;
            }
          }
        }
      }
    }
    > .user-vip-profile {
      padding: 16px;
      background: #5033aa;
      display: flex;
      width: 224px;
      flex-direction: column;
      border-radius: 10px;
      box-sizing: border-box;
      margin-bottom: 12px;
      gap: 20px;
      > div {
        display: flex;
        gap: 12px;
        > img {
          width: 48px;
          height: 48px;
        }
        > div {
          display: flex;
          flex-direction: column;
          gap: 6px;
          > h6 {
            font-size: 22px;
            font-weight: 500;
          }
          > p {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
          }
        }
      }
      > section {
        display: flex;
        flex-wrap: nowrap;
        gap: 6px;
        > img {
          width: 24px;
          height: 24px;
        }
        > div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          > span {
            opacity: 0.6;
            text-align: center;
            font-size: 10px;
            line-height: 10px;
          }
          > nav {
            display: flex;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            width: 100%;
            height: 4px;
            > span {
              transition: 0.2s;
              display: block;
              border-radius: 10px;
              height: 4px;
              background: var(--blueGradient);
            }
          }
        }
      }
    }
    > .game-history-block {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > a {
        margin-top: 6px;
        align-self: center;
      }
      > section {
        box-sizing: border-box;
        padding-inline: 16px;
        display: flex;
        margin-bottom: 10px;

        > p {
          width: 140px;

          &:last-child {
            width: 103px;
          }
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > section {
          display: none;
        }
        > button {
          &.open + section {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          cursor: pointer;
          background: #5033aa;
          border-radius: 15px;
          color: white;
          text-align: left;
          padding: 10px 15px;
          font-family: "Gotham";
          outline: none;
          border: 0;
          box-sizing: border-box;
          position: relative;
        }
        > section > div {
          display: flex;
          background: #5033aa;
          border-radius: 15px;
          padding: 10px 16px;
          box-sizing: border-box;
          > div {
            display: flex;
          }
          > nav {
            display: none;
          }
          > div > p {
            width: 140px;
            &:last-child {
              width: 103px;
            }
            font-size: 12px;
            opacity: 0.8;
            &.success {
              font-weight: 500;
              color: #37af35;
            }
            &.waiting {
              color: #73abdf;
              font-weight: 500;
            }
          }
        }
      }
    }
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    > .notifications-block {
      display: flex;
      gap: 8px;
      flex-direction: column;
      width: 560px;
      > div {
        width: 100%;
        box-sizing: border-box;
        &.new {
          background: var(--blueGradient);
        }
        display: flex;
        gap: 8px;
        flex-direction: column;

        > p {
          font-size: 12px;
        }
        > h6 {
          font-size: 12px;
          font-weight: 500;
          text-align: right;
          line-height: 12px;
        }
        padding: 12px 12px;
        background: var(--blueGradient);
        border-radius: 15px;
        max-width: 536px;
      }
    }
    .your-trades-header {
      margin-top: 20px;
    }

    > .your-trades-history {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > a {
        margin-top: 6px;
        align-self: center;
      }
      > section {
        box-sizing: border-box;
        padding-inline: 16px;
        display: flex;

        > p {
          width: 140px;
          opacity: 0.8;
          font-family: "Montserrat";
          &:last-child {
            width: 103px;
          }
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > div {
          display: flex;
          background: #5033aa;
          border-radius: 15px;
          padding: 10px 16px;
          box-sizing: border-box;
          > div {
            display: flex;
            align-items: center;
          }
          > nav {
            display: none;
          }
          > div > p {
            width: 140px;
            &:last-child {
              width: 103px;
            }
            font-size: 12px;
            opacity: 0.8;

            &.success {
              align-self: flex-start;
              padding: 5px 10px;
              width: auto;
              font-weight: 500;
              border-radius: 10px;
              background: #46c01b;
              margin-left: -10px;
              color: white;
            }
            &.waiting {
              width: auto;
              margin-left: -10px;
              padding: 5px 10px;
              color: white;
              border-radius: 10px;
              background: #40a3ff;
              font-weight: 500;
            }
          }
        }
      }
    }
    > .Wallet-History {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > section {
        box-sizing: border-box;
        padding-inline: 16px;
        display: flex;
        margin-bottom: 10px;

        > p {
          width: 140px;

          &:last-child {
            width: 103px;
          }
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > div {
          > nav {
            display: none;
          }

          display: flex;
          border: double 1px transparent;

          background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
          background-origin: border-box;
          box-sizing: border-box;
          background-clip: padding-box, border-box;

          border-radius: 15px;
          padding: 7px 16px;
          box-sizing: border-box;
          > div {
            align-items: center;
            display: flex;
          }
          > div > p {
            width: 140px;
            &:last-child {
              width: auto;
              padding: 5px 10px;
              margin-left: -10px;
              border-radius: 10px;
            }
            font-size: 12px;
            opacity: 0.8;

            &.success {
              font-weight: 500;
              background: #46c01b;
              color: white;
            }
            &.error {
              color: white;
              background: #df7373;
            }
            &.waiting {
              color: #73abdf;
              font-weight: 500;
            }
          }
        }
      }
    }
    > a {
      align-self: flex-start;
      display: flex;
      margin-top: 20px;
      width: 205px;
      justify-content: center;
    }
    > .wallet-stat-block {
      display: flex;

      gap: 30px;
      > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        > p {
          font-size: 15px;
          line-height: 15px;
          opacity: 0.7;
          white-space: nowrap;
        }
        > h2 {
          font-size: 24px;
          white-space: nowrap;
          line-height: 24px;
        }
      }
    }
    > .sales-list {
      display: flex;
      gap: 10px;
      > div {
        width: 205px;
        height: 205px;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        box-sizing: border-box;
        background: #5033aa;
        border-radius: 10px;
        > img {
          width: 101px;
          height: 101px;
          align-self: center;
        }
        > h5 {
          color: #ffb905;
          font-family: "Montserrat";
          font-size: 40px;
          line-height: 40px;
          font-weight: 700;
        }
        > p {
          font-weight: 500;
          font-family: "Montserrat";
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    > nav {
      display: flex;
      gap: 8px;
      &.your-trades-header > p {
        font-family: "Montserrat";
        margin-bottom: 8px;
      }
      > .promocode-field {
        display: flex;
        gap: 6px;
        margin-bottom: 12px;
      }
      > .reload-block {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
      }

      > p {
        > span.bronze {
          color: var(--bronze);
          font-weight: 500;
        }
        > span.silver {
          color: var(--silver);
          font-weight: 500;
        }
        > span.gold {
          font-weight: 500;
          color: var(--gold);
        }
        > span.diamond {
          font-weight: 500;
          color: var(--diamond);
        }
      }
      > .trade-input-selector {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
        & + a {
          margin-top: 4px;
          align-self: flex-start;
          width: 205px;
        }
      }
      > .selector {
        margin-bottom: 12px;
      }
      position: relative;
      max-width: 440px;
      flex-direction: column;
      > p {
        opacity: 0.8;
      }

      > span {
        opacity: 0.5;
        font-size: 12px;
        line-height: 14px;
        max-width: 100%;
      }
      > input {
        max-width: 100%;
      }
    }
    > div {
      display: flex;
      gap: 30px;
      > nav {
        > .ref-cont {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        .button-select_r {
          display: flex;

          flex-wrap: nowrap;
          gap: 8px;
          > a {
            padding: 8px 12px;
            border-radius: 12px;
            &.selected {
              background: var(--purple);
            }
            white-space: nowrap;
          }
        }
        .refStat {
          display: flex;
          flex-direction: column;
          gap: 8px;
          min-width: min(330px, 100%);
          width: 100%;
          margin-bottom: 12px;
          > div {
            align-items: center;
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            > div {
              display: flex;
              gap: 8px;
              > span {
                opacity: 0.7;
                font-size: 14px;
              }
            }
            > p {
              position: relative;

              font-size: 18px;
              line-height: 14px;
            }
            align-items: center;
            border: double 1px transparent;
            background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
            background-origin: border-box;
            background-clip: padding-box, border-box;
            display: flex;
            gap: 8px;
            box-sizing: border-box;
            padding: 14px;
            border-radius: 15px;
          }
        }
        .referrals {
          display: flex;
          flex-direction: column;
          gap: 8px;
          min-width: min(330px, 100%);
          > p {
            width: 100%;
            border: double 1px transparent;
            border-radius: 6px;
            box-sizing: border-box;
            background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
            background-origin: border-box;
            background-clip: padding-box, border-box;

            text-align: center;
            padding: 14px;
            border-radius: 15px;
            font-size: 14px;
            opacity: 0.8;
          }
          > div {
            width: 100%;
            padding: 14px;
            box-sizing: border-box;
            gap: 6px;
            align-self: center;
            > p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
            }
            > span {
              font-size: 14px;
              font-weight: 500;
              color: #22c02f;
            }
            border: double 1px transparent;
            border-radius: 6px;
            background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
            background-origin: border-box;
            background-clip: padding-box, border-box;

            display: flex;
            justify-content: space-between;
            border-radius: 15px;
          }
        }

        > .selector {
          &.nomargin {
            margin-bottom: 0;
          }
          margin-bottom: 12px;
        }
        > span {
          font-size: 12px;
          opacity: 0.8;
          margin-top: 4px;
        }
        > img.qr-code-2FA {
          width: 148px;
          height: 158px;
          border-radius: 15px;
        }
        width: 205px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        > p {
          font-size: 14px;
          > span.red_span {
            font-size: 12px;
            color: #a61c1c;
            font-weight: 500;
          }
          > span.green_span {
            font-size: 12px;
            color: #22c02f;
            font-weight: 500;
          }
          opacity: 0.8;
        }
      }
    }
  }
}
.toggle-switch {
  width: 41px;
  display: flex;
  position: relative;
  input {
    display: none;
  }
  span {
    width: 100%;
    transition: 0.3s;
    height: 22px;
    box-sizing: border-box;
    position: relative;
    border: double 1px transparent;
    border-radius: 6px;
    background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    border-radius: 6px;
    &::after {
      content: "";
      position: absolute;
      top: 3px;
      width: 13px;
      height: 13px;
      transition: 0.2s;
      left: 3px;
      border-radius: 3px;
      background: var(--purpur);
    }
  }
  input:checked + span {
    background: var(--blueGradient);
    border: 1px solid #5033aa;
    &::after {
      left: 21px;
      background: white;
    }
  }
}
.male-selector {
  display: flex;
  gap: 10px;
  > label {
    display: flex;
    gap: 7px;
    align-items: center;
  }
}
label {
  cursor: pointer;
}
input[type="checkbox"] {
  position: relative;
  box-sizing: border-box;

  outline: none;
  min-width: 22px;
  height: 22px;
  border-radius: 6px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  border: double 1px transparent;
  border-radius: 6px;
  background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  &:checked {
    background: var(--purpur);
  }

  &:checked::after {
    width: 14px;
    height: 14px;

    content: url("./../../public/images/check.svg");
    position: absolute;
    left: 3px;
    top: 3px;
  }
}
.profile-menu-list {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  gap: 6px;
  > a {
    display: flex;
    padding: 12px 8px;
    border-radius: 10px;
    > p {
      font-size: 14px;
      font-family: "Montserrat";
      font-weight: 500;
    }
    gap: 6px;
    align-items: center;
    align-self: stretch;
    justify-content: left;
    &:hover {
      background: #a360cd80;
    }
    &.selected {
      background: var(--blueGradient);
    }
  }
  > section {
    gap: 11px;
    padding: 5px 0 5px 24px;
    display: flex;
    flex-direction: column;

    > a {
      font-family: "Montserrat";
      font-size: 12px;
      &.selected {
        background: var(--blueGradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.Profile-Block__Verification-Popup {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 30px 35px 20px 35px;
  border-radius: 30px;
  background: var(--dark);
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  max-width: 366px;
  margin-top: -60px;
  border: double 1px transparent;

  background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 15;
    cursor: pointer;
  }
  > section {
    margin-top: 24px;
    margin-bottom: 40px;
    border: 2px solid #7762cd;
    border-radius: 5px;
    box-sizing: border-box;
    width: 233px;
    height: 113px;
    display: flex;

    align-items: center;
    position: relative;
    > label {
      display: flex;

      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      > input {
        display: none;
      }
      > p {
        opacity: 0.8;
        max-width: 120px;
        font-size: 12px;
        > a {
          font-weight: 500;
          color: var(--purpur);
        }
      }
    }
  }
  > a {
    width: 100%;
  }
  > h2 {
    margin-bottom: 12px;
    font-size: 24px;
  }
  > span {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.8;
    margin-bottom: 14px;
  }
  > p {
    font-size: 15px;
    > a {
      font-weight: 500;
      color: var(--purpur);
    }
  }
  z-index: 10;
  cursor: auto;
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    justify-content: center;
    background: #000000b7;

    display: flex;
    align-items: center;
  }
}
.password-input {
  position: relative;
  > input {
    max-width: 100%;
    width: 100%;
  }
  > input[type="password"] {
    & + a > svg:first-child {
      display: none;
    }
    & + a > svg:last-child {
      display: block;
    }
  }
  > a {
    right: 8px;
    display: flex;
    align-items: center;
    top: calc(50% - 8px);
    width: 16px;
    > svg {
      pointer-events: none;
    }
    > svg:last-child {
      display: none;
    }
    > svg {
      width: 16px;
      height: 16px;
    }
    height: 16px;
    position: absolute;
  }
}

.left-right-select {
  width: 420px;
  max-width: calc(100svw - 32px);

  &.y > div {
    min-width: 80px;
    flex-direction: column;
  }
  display: flex;
  gap: 5px;
  justify-content: space-between;
  > div {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      gap: 5px;
      max-width: 140px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      > img {
        height: 22px;
      }
      > svg {
        width: 22px;
        height: 22px;
      }
    }

    padding: 8px 6px;
    border: 1px solid #8c53ff;
    border-radius: 5px;
    cursor: pointer;
    width: calc(50% - 2.5px);
    > p {
      color: white;
    }
    > svg > g > path {
      fill: white;
    }
    &.selected {
      background: #8c53ff;
    }
    > svg,
    > div > svg,
    div > img,
    > div {
      user-select: none;
      pointer-events: none;
    }
    > svg > g > path {
      fill: white;
    }

    > p {
      font-size: 12px;
      font-weight: 500;
      user-select: none;
      pointer-events: none;
      color: white;
    }
  }
}
.popup--fs .left-right-select {
  max-width: 100%;
}
.deposit-bonus-select {
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  > div {
    cursor: pointer;
    > svg {
      user-select: none;
      pointer-events: none;
    }
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    background: #5033aa99;

    > svg > path {
      stroke: white;
    }
    &:last-child {
      background: #df7373b2;
    }

    &.selected {
      background: #8c53ff;
      &:last-child {
        background: #df7373;
      }
    }
  }
}

.wager--form {
  padding: 24px;
  position: relative;
  border-radius: 16px;
  background: #1e143e;
  width: 380px;
  max-width: 100%;

  > h3 {
    margin-bottom: 32px;
  }
  > a {
    margin-top: 22px;
    display: block;
  }
}
.popup--fs {
  position: fixed;
  display: none;
  &.open {
    display: flex;
  }
  align-items: center;
  width: 100svw;
  height: 100dvh;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  background: #000000b3;
  z-index: 1000;
  > div {
    * {
      font-family: "Montserrat";
    }
    > h1 {
      font-family: "Montserrat";
      font-weight: 500;
    }
  }

  .depositPopup__form {
    padding-top: 50px;
    a:last-child {
      border: double 1px transparent;
      box-sizing: border-box;
      background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }
  }
  .register-popup,
  .login-popup,
  .send-popup,
  .depositPopup {
    &__form {
      position: relative;
      > svg {
        position: absolute;
        display: block;
        cursor: pointer;
        top: 50px;
        right: 45px;
        > path {
          stroke: #8c53ff;
        }
      }
      box-sizing: border-box;
      padding: 40px 40px 20px 40px;
      background: var(--dark);
      border-radius: 20px;
      border: 2px solid var(--purpur);
      -webkit-box-shadow: 0px 0px 20px 0px rgba(119, 98, 205, 1);
      -moz-box-shadow: 0px 0px 20px 0px rgba(119, 98, 205, 1);
      box-shadow: 0px 0px 20px 0px rgba(119, 98, 205, 1);
      > h1 {
        margin-bottom: 40px;
      }
      > div {
        display: flex;
        gap: 30px;

        > nav {
          > label {
            margin-top: 4px;
            display: flex;
            gap: 7px;
            align-items: center;
            > p {
              user-select: none;
            }
            > p > a {
              color: var(--blue);
              font-weight: 500;
              text-decoration: underline;
            }
          }
          > input,
          > label {
            margin-bottom: 12px;
          }
          > .selector {
            &.nomargin {
              margin-bottom: 0;
            }
            margin-bottom: 12px;
          }
          > span {
            font-size: 12px;
            opacity: 0.8;
            margin-top: 4px;
          }
          > img.qr-code-2FA {
            width: 148px;
            height: 158px;
            border-radius: 15px;
          }
          width: 402px;
          > input {
            width: 100%;
            max-width: 100%;
          }
          > a.forgot {
            align-self: flex-end;
            font-size: 12px;
            opacity: 0.8;
          }
          .password-input {
            margin-bottom: 0;
          }
          > a:last-child {
            margin-top: 16px;
          }
          > .selector {
            max-width: 100%;
          }
          display: flex;
          gap: 8px;
          flex-direction: column;
          > p {
            > span.red_span {
              font-size: 12px;
              color: #a61c1c;
              font-weight: 500;
            }
            > span.green_span {
              font-size: 12px;
              color: #22c02f;
              font-weight: 500;
            }
            opacity: 0.8;
          }
        }
      }
    }
  }
}
.login-popup__form > div > nav > label {
  margin-top: 0 !important;
}
.MobileSupport {
  z-index: 100;
  box-shadow: 0 0 6px rgb(119, 98, 205);
  display: none;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  position: fixed;
  bottom: 24px;
  right: 36px;
  width: 64px;
  height: 64px;
  background: var(--purple);
  border-radius: 50%;
  transition: 0.2s box-shadow;
  &:hover {
    box-shadow: 0 0 12px rgb(119, 98, 205);
  }
  > svg {
    width: 36px;
    transform: scaleX(-1);
    height: 36px;
  }
}
.popup--fs .login-popup__form > div {
  flex-direction: column;
}
.close-popup-svg {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 20px;
  right: 20px;

  > path {
    stroke: #8c53ff;
  }
}
.deposit-popup {
  &__form {
    position: relative;
    input {
      height: 48px;
      padding-inline: 40px;
    }
    .left-right-select > div {
      padding: 13px 20px;
      border-radius: 10px;
    }
    .selector {
      .selector__header {
        height: 48px;
        padding-inline: 40px;
        > p {
          font-size: 15px;
        }
      }
      .selector__body > div {
        padding-inline: 40px;
      }
    }
    > svg {
      position: absolute;
      display: block;
      cursor: pointer;
      top: 20px;
      right: 20px;

      > path {
        stroke: #8c53ff;
      }
    }
    box-sizing: border-box;
    padding: 50px 40px 20px 40px;
    background: var(--dark);
    border-radius: 20px;
    border: 2px solid var(--purpur);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(119, 98, 205, 1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(119, 98, 205, 1);
    box-shadow: 0px 0px 20px 0px rgba(119, 98, 205, 1);
    > h1 {
      margin-bottom: 40px;
    }
    input {
      border: 1px solid #7762cd;
    }
    > div {
      display: flex;
      gap: 30px;

      > nav {
        > .selector {
          &.nomargin {
            margin-bottom: 0;
          }
          margin-bottom: 12px;
        }

        > span {
          font-size: 12px;
          opacity: 0.8;
          margin-top: 4px;
        }
        > img.qr-code-2FA {
          width: 148px;
          height: 158px;
          border-radius: 15px;
        }
        width: 402px;
        > input {
          width: 100%;
          max-width: 100%;
        }
        > a {
          margin-top: 16px;
        }
        > .selector {
          max-width: 100%;
        }
        display: flex;
        gap: 8px;
        flex-direction: column;
        > p {
          > span.red_span {
            font-size: 12px;
            color: #a61c1c;
            font-weight: 500;
          }
          > span.green_span {
            font-size: 12px;
            color: #22c02f;
            font-weight: 500;
          }
          font-family: "Montserrat";
          opacity: 0.8;
        }
      }
    }
  }
}
.bonuses-block {
  .sales-list {
    max-width: 663px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    > div {
      width: 205px;
      height: 205px;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      box-sizing: border-box;
      background: #5033aa;
      border-radius: 10px;
      > img {
        width: 101px;
        height: 101px;
        align-self: center;
      }
      > h5 {
        color: #ffb905;
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
      }
      > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  > div:first-child {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    max-width: 100%;
    > img {
      width: 320px;
    }

    gap: 40px;
    > nav {
      display: flex;
      gap: 12px;
      flex-direction: column;
    }
  }
  > section {
    display: flex;
    gap: 16px;
    align-items: center;
    > h2 {
      font-size: 24px;
    }
    margin-bottom: 16px;
    > a {
      font-size: 12px;

      line-height: 12px;
      padding: 8px 12px;
    }
  }
}

.payments-block {
  > section {
    display: flex;
    gap: 16px;
    align-items: center;
    > h2 {
      font-size: 24px;
    }
    margin-bottom: 16px;
    > a {
      font-size: 14px;

      line-height: 12px;
      padding: 12px 35px;
    }
  }
  > div:first-child {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    max-width: 100%;
    > nav > p {
      font-size: 14px;
    }
    > img {
      width: 320px;
    }

    gap: 40px;
    > nav {
      display: flex;
      gap: 12px;
      flex-direction: column;
    }
  }
  > .deposit-types-block {
    margin-bottom: 24px;
  }
  > .deposit-types-block,
  .withdraw-types-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > a {
      margin-top: 6px;
      align-self: center;
    }
    > section {
      box-sizing: border-box;
      padding-inline: 16px;
      display: flex;
      margin-bottom: 2px;

      > p {
        width: 140px;
        &:nth-child(4) {
          width: 197px;
        }
        &:last-child {
          width: 183px;
        }
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > div {
        display: flex;
        border: double 1px transparent;
        border-radius: 10px;
        background-image: linear-gradient(var(--dark), var(--dark)), var(--btnStroke);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 15px;
        padding: 10px 16px;
        box-sizing: border-box;
        > nav {
          display: none;
        }
        > div {
          display: flex;
        }
        > div > p {
          width: 140px;
          white-space: nowrap;
          &:last-child {
            width: 183px;
          }
          &:nth-child(4) {
            width: 197px;
          }
          font-size: 12px;
          opacity: 0.8;
          &.success {
            font-weight: 500;
            color: #37af35;
          }
          &.waiting {
            color: #73abdf;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .footer {
    padding-inline: calc(50% - 440px);

    &__right {
      gap: 65px;
    }
  }
}
.back--button {
  background: #182533;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 10001;
  align-items: center;
  justify-content: center;
  > svg {
    width: 32px;
    height: 32px;
  }
}
.percent-win--modal--in {
  padding: 10px;
  background: #ffffff1a;
  border-radius: 14px;
}
@media (max-width: 960px) {
  .Profile-Block__container h1 {
    font-size: 24px;
  }
  .wheel-spin-block__content .wheel-block .dogs {
    top: 315px;
  }
  .wheel-spin-block {
    overflow: hidden;
    max-height: 100svh;
  }
  .wheel-spin-block__content .wheel-block {
    height: 500px !important;
  }
  .coins1 {
    top: -553px !important;
    left: auto !important;
    right: calc(50% - 100px) !important;
    transform: scale(0.45);
  }
  .coins2 {
    left: auto !important;
    right: -160px !important;
    top: -460px !important;
    transform: rotate(27deg) scale(0.45);
  }
  .wheel-spin-block__content .wheel-block .panda {
    display: none;
  }
  .wheel-spin-block__content .wheel-block .zeus {
    left: -143px;
    width: 283px;
    top: 250px;
  }
  .wheel-block__spin {
    width: 142px !important;
    height: 142px !important;
    > button {
      width: 127px !important;
      height: 127px !important;
      margin-top: 0 !important;
    }
  }
  .wheel-spin-block__content .wheel-block__wheel {
    > svg {
      width: 600px;
      height: 600px;
      margin-left: 205px !important;
      margin-top: 50px !important;
    }
  }
  .wheel-spin-block__content {
    .dogs {
      width: 348px !important;
      left: calc(50% + 42px) !important;
    }
  }
  .wheel-spin-block__content .wheel-block__spin {
    top: 130px;
    left: calc(50% - 71px);
  }
  .wheel-block__thumbler {
    width: 63px;
    height: 54px;
  }
  .wheel-spin-block__content > button {
    margin-top: 14px !important;
    margin-bottom: 45px;
  }
  .wheel-logo {
    width: 128px !important;
    left: calc(50% - 64px) !important;
    top: 57px !important;
    height: 78px !important;
  }
  .wheel-spin-block__content {
    padding-top: 150px;
  }
  .wheel-spin-block__content > h1 {
    font-size: 28px !important;
    line-height: 32px !important;
    max-width: 230px !important;
  }
  .wheel-spin-block__content button {
    font-size: 16px !important;
    padding: 12px 20px !important;
    line-height: 1 !important;
  }
  @media (max-height: 850px) {
    .wheel-logo {
      width: 90px !important;
      height: 55px !important;
      left: calc(50% - 45px) !important;
      top: 15px !important;
    }
    .wheel-spin-block {
      min-height: 100svh !important;
    }
    .wheel-spin-block__content .wheel-block .zeus {
      top: 180px !important;
    }
    .coins2 {
      top: -380px !important;
    }
    .wheel-form {
      max-height: 100% !important;
    }
    .coins1 {
      top: -443px !important;
    }
    .wheel-spin-block__content .wheel-block .dogs {
      top: 230px;
    }
    .wheel-spin-block__content {
      padding-top: 80px !important;
    }
    .wheel-spin-block__content > h1 {
      font-size: 24px !important;

      line-height: 30px !important;
    }
    .wheel-spin-block__content > button {
      margin-top: 8px !important;
      margin-bottom: 10px !important;
    }
  }
  .button-select_r {
    flex-direction: column;
    text-align: center;
  }

  .Profile-Block__fields > .Wallet-History > div > div > div {
    align-items: flex-start;
    > p:last-child {
      margin-left: 0;
    }
  }
  .footer {
    padding-top: 65px !important;
  }
  .footer__right a {
    font-size: 12px !important;
  }
  .selector__header {
    padding-inline: 8px;
  }
  .search-input > input {
    &::placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder,
    &:-moz-placeholder {
      padding-left: 0;
    }
  }
  .search-input::after {
    right: 10px;
  }
  .popup--fs {
    background: var(--dark);
    width: 100%;
    align-items: flex-start;
    .send-popup__form {
      width: 100%;
      height: 100dvh;
      border: 0;
      border-radius: 0;
    }
  }

  .popup--fs > div {
    top: 0;

    max-height: calc(100% - 48px);
    overflow-y: auto;
  }
  .popup--fs .register-popup__form,
  .popup--fs .send-popup__form,
  .popup--fs .login-popup__form {
    padding-inline: 16px;
  }
  .header__right__list {
    display: none;
  }

  .Mobile-ProfileRight {
    display: flex;
    > svg {
      cursor: pointer;
    }
    > div {
      margin-inline: auto;
      max-width: calc(100% - 32px);
      width: 100%;
      padding-top: 84px;
      display: flex;
      > a {
        padding: 0;
        > * {
          pointer-events: none;
        }
        &:hover {
          background: transparent !important;
        }
      }
      flex-direction: column;
      gap: 12px;
    }
  }
  .Mobile-Selector {
    > a {
      white-space: nowrap;
    }
    display: flex;
  }
  .games__header {
    &__right {
      width: 100%;
    }
    .search-input {
      width: 100%;
      > input {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
    gap: 12px;
    flex-direction: column-reverse;
    &__left {
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      .game-type-selector {
        overflow-x: auto;
        margin-left: 0;
        max-width: 100%;
        > li {
          min-width: 56px;
          width: 56px;
          flex-direction: column;
          gap: 4px;
          height: 56px;
          border-radius: 12px;
          > span {
            position: relative;
            top: 0;
            display: inline;
            background: 0;
            color: white;
            opacity: 0.8;
            font-size: 10px;
            line-height: 12px;
            height: 12px;
            letter-spacing: -2%;
            padding: 0;
            &::after {
              display: none;
            }
          }
          > svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .selector {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .Profile-Block__fields > .game-history-block {
    > section {
      display: none !important;
    }
    > div > section > div {
      > div {
        flex-direction: column;
        gap: 12px;
        > p {
          font-size: 12px;
          line-height: 13px;
        }
      }
      > nav {
        width: 50%;
        display: flex !important;
        flex-direction: column;
        gap: 12px;
        > p {
          font-size: 12px;
          line-height: 13px;
        }
      }
    }
  }
  .your-trades-history {
    > section {
      display: none !important;
    }
    > div > div {
      > div {
        flex-direction: column;
        gap: 12px;
        > p {
          font-size: 12px;
          line-height: 13px;
        }
      }
      > nav {
        width: 50%;
        display: flex !important;
        flex-direction: column;
        gap: 12px;
        > p {
          font-size: 12px;
          line-height: 13px;
        }
      }
    }
  }
  .payments-block > .deposit-types-block,
  .payments-block > .withdraw-types-block {
    width: 100%;

    > section {
      display: none;
    }
  }
  .payments-block > .deposit-types-block > div,
  .payments-block .withdraw-types-block > div {
    max-width: 100%;
    > div {
      padding: 16px !important;
      > div {
        display: flex !important;
        flex-direction: column;
        gap: 12px;
        font-size: 12px;

        line-height: 13px;
      }
      > nav {
        display: flex !important;
        flex-direction: column;
        gap: 12px;
        font-size: 12px;
        min-width: 55%;
        line-height: 13px;
      }
    }
  }
  .text-page-container {
    > h1 {
      margin-bottom: 24px;
    }
    > h2 {
      font-size: 16px;
    }
    > p {
      margin-bottom: 12px;
    }
  }
  .main-first-screen .swiper-selector {
    justify-content: center;
  }
  .main-first-screen .swiper {
    height: auto;
    > .swiper__wrapper {
      gap: calc(3000px - 100svw + 32px);
    }
    > .swiper__wrapper > div {
      flex-direction: column;
      align-items: center;
      max-width: calc(100svw - 32px);
      min-width: calc(100svw - 32px);

      > div {
        > a {
          margin-left: 0;
          margin-bottom: 15px;
        }
        align-items: center;
        > h1 {
          margin-bottom: 12px;
        }
        > p {
          text-align: center;
        }
        > ul {
          padding-left: 10px;
          gap: 6px;
          > li {
            font-size: 12px;
          }
        }
      }
      > img {
        width: 100%;
        margin-bottom: -20px;
        height: auto;
        transform: none;
        //  max-width: 260px;
      }
    }
  }
  .faq-container > p {
    font-size: 14px;
  }
  .faq-container > nav {
    gap: 12px;
    > div {
      > p,
      span {
        border-radius: 12px;
        width: 100%;
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
        padding: 12px 16px;
      }
      gap: 12px;
    }
  }
  .NotFoundBlock img {
    width: 260px;
  }
  .payments-block > div:first-child,
  .bonuses-block > div:first-child {
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 24px;
    > nav > p {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .sales-list {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    > div {
      max-width: 210px;
    }
  }
  .Profile-Block__fields {
    > nav {
      max-width: 100%;
    }
    .selector {
      max-width: 100%;
    }
    margin-top: 24px;
    > .wallet-stat-block {
      flex-direction: column;
      gap: 12px;
      > div {
        gap: 8px;
      }
    }
  }

  .games-list > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 27px;
    max-width: 500px;
    margin-inline: auto;
    > a {
      max-width: 180px !important;
      width: 100%;
      > img {
        height: 138px !important;
      }
      &:hover::after {
        top: 45px !important;
        width: 48px !important;
        height: 48px !important;
        background-size: 100%;
      }
      &:nth-child(5) {
        max-width: 380px !important;
        > img {
          width: 380px !important;
          height: 280px !important;
        }
        &:hover::after {
          height: 64px !important;
          width: 64px !important;
          background-size: 100%;
          top: 108px !important;
        }
      }
    }
  }
  .login-popup,
  .register-popup,
  .deposit-popup,
  .depositPopup {
    display: flex;
    transition: 0.3s;
    transform: translateX(-110vw);
    &.open {
      transform: translateX(0);
    }
  }
  .depositPopup__form,
  .login-popup__form,
  .register-popup__form {
    max-width: 100%;
    width: 100%;
    height: 100%;
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 0 !important;
    flex-direction: column;
    display: flex;
  }
  .popup--fs > div > div > nav {
    width: 100% !important;
    .selector__header {
      height: 36px;
    }
    input[type="text"],
    input[type="password"] {
      height: 36px;
    }
    a.btn {
      margin-top: 4px;
    }
  }
  .header .selector__header {
    height: 36px;
  }
  a.btn,
  a.white-btn {
    padding: 9px 16px;
  }
  .Profile-Block {
    padding-top: 45px;
    flex-direction: column;
    &__container {
      width: 100%;
      max-width: calc(100svw - 32px);
      margin-inline: auto;
    }
    &__fields {
      .reload-block {
        flex-direction: column;
        a {
          width: 100%;
          max-width: 100%;
        }
      }
      > a {
        align-self: stretch;
        width: 100%;
        margin-top: 12px;
      }
      gap: 12px;
      .selector__header {
        height: 36px;
      }
      .promocode-field {
        > a {
          width: 36px;
          height: 36px;
        }
      }
      input[type="text"],
      input[type="password"],
      input[type="number"] {
        height: 36px;
        width: 100%;
        max-width: 100%;
      }
      > nav {
        > a.btn {
          width: 100%;
        }
      }
      > div {
        flex-direction: column;
        max-width: 100%;
        .selector {
          max-width: 100%;
        }
        gap: 12px;
        > a.btn {
          width: 100%;
        }
        > nav {
          > a.btn {
            width: 100%;
          }
          width: 100%;

          .selector__body {
            > div {
              height: 36px;
            }
          }
        }
      }
    }
  }

  .profile-menu-list {
    display: none;
  }
  h1:not(.wheel-spin-block__content > h1, .Profile-Block__container > h1) {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .main-first-screen {
    padding: 44px 0 50px 0;
  }
  .main-first-screen .swiper > .swiper__wrapper > div > div {
    padding-top: 0;
  }
  .footer-providers {
    min-height: 32px;
    margin-top: 68px;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100px;
    max-width: 100%;
  }
  .main-games-list {
    padding-top: 34px;
  }
  .container {
    margin-inline: auto;
    max-width: calc(100% - 32px);
  }
  .header {
    padding-inline: 16px;
    .notifications-toggle {
      display: none;
    }
    .user-block > div,
    .user-block > svg {
      display: none;
    }
    &__left {
      &__logo {
        width: 70px;
        > img {
          width: 70px;
          height: 70px;
          transform: translateY(-15px);
        }
      }
      &__burger-toggle {
        display: none;
      }
    }
    &__right {
      .user-block {
        > img {
          width: 28px;
          height: 28px;
        }
        margin-left: 4px;
      }
      .btn:not(:last-child) {
        display: none;
      }
      .hml {
        position: absolute;
        visibility: hidden;
      }
      > .language-toggle {
        display: none;
      }
    }
  }
  .footer {
    padding: 23px 0 80px 0;
    &__logo {
      margin-bottom: 8px;
    }
    &__right {
      flex-direction: column;
      align-items: center;
      > div {
        align-items: center;
        gap: 12px;
      }
    }
    flex-direction: column !important;
    gap: 22px;
    &__left {
      > span {
        text-align: center;
        margin-bottom: 14px;
      }
      align-items: center;
    }
    .age-block {
      width: 71px;
      height: 71px;
      > p {
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
  .MobileMenu,
  .Profile-MobileMenu,
  .SearchMenu {
    display: flex !important;
  }
  .MobileSupport {
    right: 12px;
    bottom: 60px;
    width: 36px;
    height: 36px;
    > svg {
      width: 20px;
      height: 20px;
    }
  }

  .NotFoundBlock > .container > h1 {
    font-size: 48px !important;
    line-height: 54px !important;
  }
  .Wallet-History {
    > div > div {
      padding: 16px !important;
    }
    > div > div > nav {
      display: flex !important;
      flex-direction: column;
      gap: 12px;
      font-size: 12px;
      line-height: 13px;
      width: 50%;
    }
    > div > div > div {
      flex-direction: column;
      gap: 12px;
      > p {
        font-size: 12px;
        line-height: 13px;
      }
    }
    > section {
      display: none !important;
    }
  }
  .wager--form {
    background: transparent;
  }
}

@media (max-width: 489px) {
  .sales-list {
    width: 100%;
    flex-direction: column !important;
    gap: 12px !important;
    > div {
      > img {
        width: 126px !important;
        height: 126px !important;
      }
      > h5 {
        font-size: 48px !important;
        margin-bottom: 4px !important;
      }
      > p {
        font-size: 16px !important;
      }
      padding-bottom: 15px !important;
      height: auto !important;
      width: 100% !important;
      max-width: 99999px !important;
    }
  }
}
@media (min-width: 1280px) {
  .animation-Loading > img {
    height: 100%;
  }
}
@media (max-width: 465px) {
  .games-list > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 27px;
    > a {
      max-width: 121px !important;
      width: 100%;
      > img {
        height: 93px !important;
      }
      &:hover::after {
        top: 28px !important;
        width: 36px !important;
        height: 36px !important;
        background-size: 100%;
      }
      &:nth-child(5) {
        max-width: 269px !important;
        > img {
          width: 267px !important;
          height: 205px !important;
        }
        &:hover::after {
          height: 64px !important;
          width: 64px !important;
          background-size: 100%;
          top: 70px !important;
        }
      }
    }
  }
}
.container-ref-img {
  max-width: 700px;
  width: calc(100svw - 32px);
  position: relative;
  > img {
    width: 100%;
    border-radius: 10%;
    height: 100%;
  }
}
.MobileMenu {
  display: none;
  position: fixed;
  bottom: -1px;
  left: 0;
  box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.25);
  width: 100svw;
  background: #221452;
  padding: 11px 20px 9px 20px;
  box-sizing: border-box;
  align-items: flex-end;
  > a {
    display: flex;
    gap: 5px;

    > *:not(a) {
      pointer-events: none;
    }
    &:not(&:nth-child(3)) {
      padding: 6px 10px;

      &.open {
        border-radius: 4px;
        background: #422397;
      }
    }
    padding-bottom: 6px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;

    > svg {
      width: 14px;
      height: 14px;
      > path,
      > g > path {
        stroke: #6b41e9;
      }
    }
    > p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
    position: relative;
    > a.btn {
      width: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      position: absolute;
      top: 0;
      margin-top: -50px;
      background: linear-gradient(85.32deg, #8a2be9 3.78%, #32a5e5 48.83%, #3dffdc 96.22%);

      height: 40px;
    }
  }
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 1000;
}
.SearchMenu {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  section {
    width: 100%;

    > div {
      width: 100%;
    }
    > nav.result {
      position: absolute;
      max-width: calc(100% - 32px);

      margin-top: 12px;
      transition: 0.1s;
      flex-direction: column;
      box-sizing: border-box;
      flex-wrap: wrap;
      display: flex;
      width: 100%;
      border-radius: 10px;
      border: 2px solid var(--purpur);

      z-index: 1050;
      background: var(--dark);

      padding: 12px 0;
      > a {
        display: flex;
        padding-right: 6px;
        padding-left: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        height: 36px;
        cursor: pointer;
        * {
          pointer-events: none;
        }
        user-select: none;
        &:hover {
          background: #291561;
          > p {
            color: white;
          }
        }
        width: 100%;
        display: flex;
        gap: 6px;
        align-items: center;
        > p {
          align-items: center;
          display: flex;
          gap: 6px;
          font-size: 15px;
          color: var(--purpur);
        }
      }
    }
    > div > input {
      max-width: 100% !important;
      width: 100%;
    }
    margin-bottom: 24px;
  }
  > nav {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 100%;
    padding-left: 16px;
    > a {
      display: flex;
      gap: 6px;
      > * {
        user-select: none;
        pointer-events: none;
      }
      > svg {
        width: 16px;
      }
      > p {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .selector {
    margin-top: 32px;
    width: 100%;
    max-width: 100%;
  }
  display: none;
  background: var(--dark);
  transform: translateY(100dvh);

  height: calc(100% - 40px);
  max-height: 9999px;
  transition: 0.3s;
  &.open {
    transform: translateY(0);
  }
  width: 100%;
  position: fixed;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  box-sizing: border-box;
  padding: 8px 16px;
  padding-bottom: 70px;

  > .down-button {
    width: 36px;
    margin-bottom: 40px;
    min-height: 36px;
    background: #5033aa;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
    &:hover {
      transform: scale(0.95);
    }
  }
}
.Profile-MobileMenu {
  position: fixed;
  .selector {
    margin-top: 32px;
    width: 100%;
    max-width: 100%;
  }
  .profile-menu-list {
    display: flex;
    width: 100%;
    margin-left: 0;
    a svg,
    a p {
      pointer-events: none;
    }
  }
  overflow-y: auto;
  padding-bottom: 70px;
  display: none;
  background: var(--dark);
  transform: translateY(100dvh);

  height: calc(100% - 40px);
  transition: 0.3s;
  padding-bottom: 70px;
  &.open {
    transform: translateY(0);
  }
  width: 100%;
  position: fixed;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  box-sizing: border-box;
  padding: 8px 16px;
  > .down-button {
    width: 36px;
    margin-bottom: 40px;
    min-height: 36px;
    background: #5033aa;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
    &:hover {
      transform: scale(0.95);
    }
  }
}

.telegram-log {
  background: #2aabee;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 8px 14px;
  justify-content: center;
  > svg {
    margin-inline: 4px;
  }
}
.game-frame {
  border: 0;

  &__wrapper {
    background: black;
    position: fixed;
    z-index: 10000;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  position: relative;
  z-index: 10000;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
